@import "_var.scss";
@import "base.css";
@import "common.css";
/*---------------------------------
Page CSS 2018.05
CSS 担当者名 Document 2018.05
Last Update 2018.05 担当者名
---------------------------------*/

/*
SP記述
タブレット（min-width: 668px）
PC（min-width: 1025px）
#################### TOPPAGE ####################*/
#Page.pageIndex {
    /*######### MainImg ###########*/
    #MainImg {
      padding-top: 0;
      .innerBasic {
        height: 545px;
        padding: 45px 0 15px;
        .innerBg {
          background-color: #dd2b23;
          background-image: none;
          position: relative;
          &:after {
            position: absolute;
            content: '';
            width: 100%;
            height: 100%;
            top: 15px;
            left: -30px;
            background-image: url(../img/contents/reddot_sp.png);
            background-position: top left;
            background-size: 24px 24px;
            z-index: -1;
          }
        }
        h2 {
          position: absolute;
          font-size: 44px;
          line-height: 1.14;
          letter-spacing: .05em;
          top: 160px;
          left: 15px;
          font-style: italic;
          z-index: 50;
          &:after {
            position: relative;
            right: 2px;
            bottom: 0;
            width: 21px;
            height: 42px;
            background-size: 21px auto;
            transform: translateX(7px);
          content: "";
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(../img/contents/top/icon_01.png);
          background-size: contain;
          }
        }
        p.sub {
          position: absolute;
          top: 265px;
          left:15px;
          z-index: 50;
          font-size: 18px;
          font-weight: bold;
          font-family: 'Noto Sans JP', "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          color: #dd2b23;
        }
        .image {
          &_wrap { height: 485px; }
          &_imglist {
            width: 100%;
            height: 100%;
            img {
              display: none;
            }
          }
          &_img01:before { background-image: url(../img/contents/top/mainimg_01.jpg); }
          &_img02:before { background-image: url(../img/contents/top/mainimg_02.jpg); }
        }
        .swiper-pagination-bullet { display: inline-block; }
        .scroll_wrap {
          display: block;
          width: 60px;
          right: 0;
          /*right: 30px;*/
          bottom: 195px;
          height: 118px;
          .scroll_text {
            line-height: 60px;
          }
         &:after {
          left: 50%;
         }
        }
        .text_wrap {
          display: block;
          width: 288px;
          height: 195px;
          padding: 23px 0 0 30px;
          h3 {
            font-size: 16px;
            line-height: 1.8;
            letter-spacing: .08em;
            margin-bottom: 10px;
            text-transform:none;
          }
          p {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: .08em;
            line-height: 1.85;
            padding-right: 30px;
          }
        }
      }
    }
    #MainImg.type02 {
        p.sub {
          position: absolute;
          font-size: 12px;
          line-height: 1.14;
          letter-spacing: .05em;
          font-family: 'Lato', sans-serif;
          font-style: italic;
          top: 172px;
          left: 15px;
          color: #000;
          z-index: 50;
          &:after {
            position: relative;
            right: 2px;
            bottom: 0;
            width: 7px;
            height: 15px;
            background-size: 5px auto;
            transform: translateX(6px);
          content: "";
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(../img/contents/top/icon_01.png);
          background-size: contain;
          }
        }
        h2 {
          top: 200px;
          font-size: 28px;
          font-style: normal;
          font-family: 'Noto Sans JP', "游ゴシック体", 'YuGothic', "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", 'Meiryo', "ＭＳ Ｐゴシック", "MS P Gothic", sans-serif;
          color: #dd2b23;
          line-height: 40px;
          letter-spacing: 0.08em;
          &:after {
            content: none;
          }
        }
        .text_wrap {
          h3 {
            color: #000;
          }
        }
    }
  #Main {
    .contBox {
      width: 100%;
      h3 {
        font-family: 'Lato', sans-serif;
        font-weight: 900;
        letter-spacing: .05em;
        line-height: 1;
        position: relative;
        &:after {
          position: absolute;
          content: '';
          background: url(../img/contents/top/icon_01.png) top left no-repeat;
          background-size: contain;
        }
      }
    }
    .contSubBox {
      width: 100%;
      padding: 0 15px;
      .moreBtn {
        display: inline-block;
        background-color: #dd2b23;
        background: url(../img/contents/arrow_01.png) no-repeat;
        text-decoration: none;
        color: #fff;
        span {
          font-family: 'Lato', sans-serif;
          font-weight: 900;
        }
      }
    }
    #Information {
      margin-top: -90px;
      margin-bottom: 30px;
      padding: 70px 0;
      min-height: 345px;
      background-image: url(../img/contents/top/info_bg.png);
      background-size: 75px auto;
      background-repeat: no-repeat;
      background-position: center;
      .entryBody {
        padding: 0 30px;

      }
      h3 {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: normal;
        line-height: 1.4;
        color: #dd2b23;
        &:before {
          content: '';
          margin-right: 10px;
          flex-grow: 1;
          height: 1px;
          background-color: #dd2b23;
        }
        &:after {
          position: relative;
          content: '';
          flex-grow: 1;
        }
      }
      p {

        text-align: center;
        line-height: 2;
        color: #888;
      }
    }
    #ContBox01 {
      text-align: center;
      position: relative;
      padding: 30px 0 112px;
      &:after {
        content: '';
        position: absolute;
        width: calc(100% - 30px);
        height: 100%;
        background-color: #f7f7f7;
        background-image: -moz-linear-gradient(
          -45deg,
          #fff 25%,
          #f7f7f7 25%,
          #f7f7f7 50%,
          #fff 50%,
          #fff 75%,
          #f7f7f7 75%,
          #f7f7f7
          );
        background-image: -webkit-linear-gradient(
          -45deg,
          #fff 25%,
          #f7f7f7 25%,
          #f7f7f7 50%,
          #fff 50%,
          #fff 75%,
          #f7f7f7 75%,
          #f7f7f7
          );
        background-image: linear-gradient(
          -45deg,
          #fff 25%,
          #f7f7f7 25%,
          #f7f7f7 50%,
          #fff 50%,
          #fff 75%,
          #f7f7f7 75%,
          #f7f7f7
          );
        background-position: top left;
        background-size: 7px 7px;
        top: -30px;
        left: 0;
        z-index: -1;
      }
      h3 {
        display: inline-block;
        font-size: 28px;
        margin: 0 auto;
        transform: translate(-10px, -4px);
        &:after {
          width: 18px;
          height: 40px;
          transform: translate(1px, -10px);
        }
      }
      small {
        display: block;
        font-size: 12px;
        color: #888;
        line-height: 1;
        letter-spacing: .3em;
        margin-top: 1px;
      }
      .contSubBox {
        .itemBoxs {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 30px;
          margin-bottom: 20px;
          }
        .itemBox {
          display: block;
          width: 31.5%;
          float: left;
          text-decoration: none;
          margin-bottom: 11px;
          .itemBoxImg {
            width: 100%;
            img {
              width: 100%;
              height: auto;
            }
          }
          .itemBoxText {
            font-size: 12px;
            line-height: 1.5;
            letter-spacing: .05em;
            text-align: center;
            margin: 4px 0 0;
          }
          &:nth-of-type(n+13) { display: none; }
        }
        .moreBtn {
          width: 200px;
          height: 50px;
          margin: 0 auto;
          line-height: 50px;
          font-size: 10px;
          background-size: 8px 12px;
          background-position: center right 15px;
          span {
            display: inline-block;
            margin-left: -1px;
            &:first-of-type { margin-left: 3px; }
          }
        }
      }
    }
    #ContBox02 {
      margin-top: 26px;
      .contSubBox {
        padding: 0;
        .boxItem {
          text-decoration: none;
          display: block;
          .boxIn {
            width: 100%;
            background-color: #f7f7f7;
            dt {
              img { width: 100%; }
            }
            dd {
              padding: 26px 30px 45px;
              text-align: center;
              h3 {
                display: inline-block;
                font-size: 20px;
                right: 7px;
                &:after {
                  width: 12px;
                  height: 25px;
                  bottom: 1px;
                  transform: translateX(2px);
                }
              }
              small {
                display: block;
                line-height: 1;
                font-size: 10px;
                color: #888;
                letter-spacing: .3em;
                margin-top: 4px;
              }
              p.text {
                line-height: 1.86;
                letter-spacing: .05em;
                margin-top: 22px;
                margin-bottom: 24px;
              }
              p.btn {
                width: 100px;
                background: url(../img/contents/arrow_02.png) no-repeat top right;
                background-size: 8px 12px;
                border-bottom: 1px solid #dd2b23;
                font-family: 'Lato', sans-serif;
                font-size: 10px;
                font-weight: 900;
                color: #dd2b23;
                line-height: 1;
                padding-bottom: 15px;
                margin: 0 auto;
                letter-spacing: 0;
                .btnText {
                  display: inline-block;
                  width: 100%;
                  letter-spacing: .2em;
                }
                span { display: inline-block; }
              }
            }
          }
        }
      }
    }
    #ContBox03 {
      display: none;
      padding-top: 65px;
      height: 600px;
      background-image: url(../img/contents/top/topics_logo.png);
      background-position: bottom -2px right;
      background-repeat: no-repeat;
      background-size: 216px auto;
      .contSubBox {
        display: flex;
        flex-direction: column;
      }
      .boxTitle {
        width: 100%;
        position: relative;
        clear: both;
        .boxTitleText {
          float: left;
          h3 {
            font-size: 20px;
            &:after {
              width: 17px;
              height: 25px;
              transform: translate(2px, -7px);
            }
          }
          span {
            display: inline-block;
            font-size: 10px;
            color: #888;
            letter-spacing: .15em;
            line-height: 1;
            transform: translate(-1px, -2px);
          }
        }
        .boxTitleBtn {
          float: right;
          position: relative;
          top: -2px;
          .moreBtn {
            width: 90px;
            height: 35px;
            background-position: top 11px right 9px;
            background-size: 8px 12px;
            line-height: 28px;
            padding: 0 10px;
            span {
              font-size: 10px;
              letter-spacing: .2em;
            }
          }
        }
      }
      .boxContent {
        margin-top: 24px;
        .listWrap {
          .list {
            a {
              display: block;
              width: 100%;
              height: 100%;
              border-bottom: 1px solid #e5e5e5;
              padding: 20px 50px 26px 0;
              background-image: url(../img/contents/arrow_02.png);
              background-position: center right 15px;
              background-repeat: no-repeat;
              background-size: 8px 12px;
              text-decoration: none;
            }
            time {
              font-family: 'Lato', sans-serif;
              font-weight: 400;
              font-size: 12px;
              color: #888;
              letter-spacing: .1em;
              line-height: 1.3;
            }
            p {
              margin: 0;
              margin-top: 4px;
              letter-spacing: .015em;
              text-align: justify;
            }
            &:first-of-type a { border-top: 1px solid #e5e5e5; }
            &:nth-of-type(2) a { padding-bottom: 28px; }
          }
        }
      }
    }
    #ContBox04 {
      background-image: url(../img/contents/top/message_bg.png);
      background-position: 54% 60px;
      background-size: auto 379px;
      background-repeat: no-repeat;
      background-color: #f7f7f7;
      .ImageArea {
        margin-bottom: 58px;
        img.img_sp {
          margin-top: -30px;
          width: 100%;
        }
        img.img_pc {
          display: none;
        }
      }
      .textArea {
        margin: 0 30px;
        padding-bottom: 65px;
        h3 {
          font-size: 18px;
          font-weight: 700;
          color: #dd2b23;
          line-height: 1;
          letter-spacing: .05em;
          margin-bottom: 37px;
          text-align: center;
        }
        p {
          line-height: 1.86;
          letter-spacing: .05em;
          margin-bottom: 26px;

        }
      }
    }
  }
}

/*#################### 下層共通 ####################*/
  #ServiceLineUp {
    .innerBasic {
    padding: 0 15px;
    }
    #LineUpSupBox01 {
      position: relative;
      padding-top: 50px;
      padding-bottom: 70px;
      h3 {
        font-size: 18px;
        font-weight: 700;
        color: #dd2b23;
        line-height: 1.6;
        text-align: center;
      }
      &:after {
          position: absolute;
          content: "";
          bottom:0px;
          left: 0;
          right: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #dd2b23;
      }
    }
    #LineUpSupBox02 {
      padding-top: 50px;
      padding-bottom: 75px;
      background-color: #f7f7f7;
      h4 {
        margin-bottom: 25px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
      }
      ul {
        margin-bottom: 50px;
        width: 100%;
        li {
          margin-bottom: 2px;
          width: 100%;
          text-align: left;
          a {
            position: relative;
            display: block;
            /*display: flex;*/
            display: block;
            /*align-items: center;*/
            width: 100%;
            /*height: 96px;*/
            padding: 22px 60px 22px 60px;
            background-color: #fff;
            vertical-align: middle;
            text-decoration: none;
            &:before {
              position: absolute;
              content: '';
              top:0;
              bottom:0;
              left: 0;
              margin: auto;
              width: 60px;
              height: 96px;
              background-repeat: no-repeat;
              background-position: center;
            }
            &:after {
              position: absolute;
              content: '';
              top:0;
              bottom: 0;
              right: 15px;
              margin: auto;
              width: 8px;
              height: 12px;
              background-image: url(../img/contents/arrow_02.png);
              background-position: center;
              background-size: 8px auto;
            }
          }
        }
        &:nth-of-type(2) {
          li {
            &:nth-of-type(1) {
              a {
                &:before {
                  background-image: url(../img/contents/service/icon_09.png);
                  background-size: 33px auto;
                }
              }
            }
            &:nth-of-type(2) {
              a {
                &:before {
                  background-image: url(../img/contents/service/icon_10.png);
                  background-size: 32px auto;
                }
              }
            }
          }
        }
        &:nth-of-type(3) {
          li {
            &:nth-of-type(1) {
              a {
                &:before {
                  background-image: url(../img/contents/service/icon_11.png);
                  background-size: 30px auto;
                }
              }
            }
            &:nth-of-type(2) {
              a {
                &:before {
                  background-image: url(../img/contents/service/icon_12.png);
                  background-size: 30px auto;
                }
              }
            }
          }
        }
        &:nth-of-type(4) {
          li {
            &:nth-of-type(1) {
              a {
                &:before {
                  background-image: url(../img/contents/service/icon_14.png);
                  background-size: 40px auto;
                }
              }
            }
            &:nth-of-type(2) {
              a {
                &:before {
                  background-image: url(../img/contents/service/icon_15.png);
                  background-size: 30px auto;
                }
              }
            }
          }
        }
      }
    }
  }

    #VoiceBox {
      padding-top: 27px;
      background-color: #f7f7f7;
      h3 {
        position: relative;
        margin-bottom: 50px;
        padding-top: 75px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        &:before {
          position: absolute;
          content: '';
          top: 0px;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #000;

        }
      }
      .serviceVoice {
        position: relative;
        padding-top:30px;
        margin-bottom: 45px;
        .bgRedBox {
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: calc(100% - 33px);
          z-index: 10;
        }
        .innerBox {
          position: relative;
          padding: 45px 30px 0;
          margin-left: 30px;
          z-index: 13;
          background-color: #fff;
          width:calc(100% - 45px);
          dl {
            margin: 0 auto;
            padding-bottom: 45px;
            width: 100%;
            border-bottom: #f7f7f7 solid 1px;
            dt {
              text-align: center;
              font-size: 14px;
              img {
                width: 100%;
                margin-bottom: 20px;
              }
            }
            dd {
              text-align: center;
              font-size: 10px;
              padding-right: 20px;
              a {
                position: relative;
                color: #888;
                text-decoration: none;
                vertical-align: middle;
                &:after {
                  position: absolute;
                  content:'';
                  top: 0;
                  bottom: 0;
                  right: -20px;
                  margin: auto;
                  width: 13px;
                  height: 11px;
                  background-image: url(../img/contents/icon_link.png);
                  background-size: 13px auto;
                }
              }
              &.rqCode {
                display: none;
              }
            }
          }
          .contVoice {
            margin: 0 auto;
            padding-top: 40px;
            width: 100%;
            h4 {
              margin-bottom: 25px;
              font-size: 18px;
              font-weight: bold;
            }
            p {
              font-size: 14px;
            }
            &:after {
              content: "";
              display: block;
              clear: both;
            }
          }
        .userBox {
          position: relative;
          min-height: 50px;
          img {
            float: right;
            width: 75px;
          }
          p {
            position: absolute;
            margin: 0;
            bottom: 0px;
            right: 85px;
            text-align: right;
            font-size: 12px;
            line-height: 1.75;
          }
          &:not(:has(img)){
            p {
              right: 0;
            }
          }
          &:after {
            content: '';
            clear: both;
            display: block;
          }
          &.noPhoto {
            p {
              right: 0;
            }
          }
        }
        .specBox {
          margin-top: 45px;
          padding-top: 25px;
          padding-bottom: 45px;
          border-top: #e5e5e5 solid 1px;
          font-size: 12px;
          color: #888;
        }
        }
        &:nth-of-type(2n){
          .bgRedBox {
            left: 50%;
          }
          .innerBox{
            margin-left: 15px;
          }
        }
      }
    }

/*#################### BEGINNER ####################*/
  #PageBeginner.pageIndex {
    #MainImg {
      .image {
        &_img{ background-image: url(../img/contents/beginner/mainimg.jpg); }
      }
    }
    #Main {
    #ContBox01 {
      padding-bottom: 135px;
      .innerBasic {
        padding: 0 15px;
        h3 {
          margin-bottom: 30px;
          font-size: 20px;
          font-weight: bold;
          text-align: center;
          color: #dd2b23;
        }
      }
    }
    #ContBox02 {
      position: relative;
      padding-top: 100px;
      padding-bottom: 1px;
      background-color: #f7f7f7;
      &:before {
        position: absolute;
        top: -52px;
        width: 100%;
        content: 'FEATURE';
        font-family: 'Lato', sans-serif;
        font-size: 60px;
        font-weight: 900;
        font-style: italic;
        letter-spacing: 0.05em;
        line-height: 1em;
        text-align: center;
        color: #f7f7f7;
      }
      h3 {
        position: relative;
        margin-bottom: 50px;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        &:before {
          position: absolute;
          content: '';
          top:-70px;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #000;
        }
      }
      .contSubBox {
        position: relative;
        padding-top: 175px;
        margin-bottom: 75px;
        .contentsBox {
          position: relative;
          margin: 0 15px 0 30px;
          padding: 30px;
          background-color: #fff;
          z-index: 13;
          p {
            span {
              font-size: 14px;
              color: #888888;
            }
          }
          b {
            position: relative;
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-style: italic;
            letter-spacing: 0.05em;
            line-height: 1em;
            &:after {
              position: absolute;
              bottom: -40px;
              left: 0;
              right: 0;
              margin: auto;
              content: '';
              width: 1px;
              height: 35px;
              background-color: #000;
            }
          }
          h4 {
            margin-top: 45px;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
          }
        }
        .bgRedBox {
          position: absolute;
          top: 45px;
          margin-left: 15px;
          height: 220px;
          width: calc(100% - 15px);
          background-color: #dd2b23;
          z-index: 11;
          &:before {
            content: "";
            position: absolute;
            top: -45px;
            left: -15px;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
          }
        }
      }
      #ContSubBox01 {
        .bgRedBox {
          &:before {
            background-image: url(../img/contents/beginner/img_01.jpg);
          }
        }
      }
      #ContSubBox02 {
        .contentsBox {
          margin: 0 30px 0 15px;
        }
        .bgRedBox {
          margin-left: 0px;
          &:before {
            left: 15px;
            background-image: url(../img/contents/beginner/img_02.jpg);
          }
        }
      }
      #ContSubBox03 {
        .bgRedBox {
          &:before {
            background-image: url(../img/contents/beginner/img_03.jpg);
          }
        }
      }
    }
  }
}

    #ServiceLineUp {
      padding-top: 20px;
      #LineUpSupBox02 {
        ul {
          &:nth-of-type(1) {
            li {
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_01.png);
                    background-size: 28px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_02.png);
                    background-size: 26px auto;
                  }
                }
              }
              &:nth-of-type(3) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_03.png);
                    background-size: 36px auto;
                  }
                }
              }
              &:nth-of-type(4) {
                a {
                  span {
                    display: inline-block;
                    margin-top: 8px;
                    padding: 3px 4px 4px 5px;
                    background-color: #b89447;
                    font-size: 12px;
                    color: #fff;
                    font-family: "Noto Serif JP";
                    font-weight: 400;
                    letter-spacing: .1em;
                    line-height: 1em;
                  }
                  &:before {
                    background-image: url(../img/contents/service/icon_04.png);
                    background-size: 32px auto;
                  }
                }
              }
              &:nth-of-type(5) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_05.png);
                    background-size: 25px auto;
                  }
                }
              }
              &:nth-of-type(6) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_06.png);
                    background-size: 29px auto;
                  }
                }
              }
              &:nth-of-type(7) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_07.png);
                    background-size: 30px auto;
                  }
                }
              }
              &:nth-of-type(8) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_08.png);
                    background-size: 32px auto;
                  }
                }
              }
            }
          }
          &:nth-of-type(4) {
            margin-bottom: 0;
          }
        }
      }
    }

  /*#################### SERVICE ####################*/
  #PageService {
    #ServiceLineUp {
      #LineUpSupBox02 {
        ul {
          &:nth-of-type(1) {
            li {

              &:nth-of-type(1) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_05.png);
                    background-size: 26px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_06.png);
                    background-size: 29px auto;
                  }
                }
              }
              &:nth-of-type(3) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_07.png);
                    background-size: 30px auto;
                  }
                }
              }
              &:nth-of-type(4) {
                a {
                  &:before {
                    background-image: url(../img/contents/service/icon_08.png);
                    background-size: 32px auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    #PriceBox {
      .innerBasic {
        padding: 75px 30px 0;
      h3 {
        position: relative;
        margin-bottom: 35px;
        padding-top: 70px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        &:before {
          position: absolute;
          content: '';
          top: 0px;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #000;

        }
      }
      p {
        span {
          color: #dd2a23;
        }
      }
        table {
          margin-top: 35px;
          width: 100%;
          border-top: #e5e5e5 solid 1px;
          tr {
            th,
            td {
              display: block;
            }
            th {
              padding:25px 0 5px 0;
              font-weight: normal;
              font-size: 14px;
            }
            td {
              &:nth-of-type(1) {
                position: relative;
                padding-left: 60px;
                font-size: 14px;
                font-weight: bold;
                color: #d92323;
                &:before {
                  position: absolute;
                  content: '';
                  top: 0;
                  bottom: 0;
                  left:0;
                  margin: auto;
                  width: 45px;
                  height: 1px;
                  background-color: #000;
                }
              }
              &:nth-of-type(2) {
                padding:5px 0 25px;
                border-bottom: #e5e5e5 solid 1px;
                text-align: right;
                a {
                  text-decoration: none;
                  font-size: 12px;
                  color: #888;
                  &:after {
                    display: inline-block;
                    content: '';
                    width: 8px;
                    height: 12px;
                    margin-left: 7px;
                    background-image: url(../img/contents/arrow_02.png);
                    background-size: 8px auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    #PremiumBox {
      padding-top: 70px;
      h3 {
        position: relative;
        padding-bottom: 70px;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        color: #dd2a23;
        &:after {
          position: absolute;
          content: '';
          bottom: 0;
          left: 0;
          right: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #dd2a23;
        }
      }
      .contSubBox {
        position: relative;
        padding-top: 60px;
        padding-bottom: 75px;
        background-color: #fbf5e9;
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 0;
          border-width: 80px 80px 0px 0px;
          border-style: solid;
          border-color: #b89447 #fbf5e9 #fbf5e9 #b89447;
        }
        &:after {
          position: absolute;
          top: 22px;
          left: 0;
          content: 'PREMIUM';
          color: #fff;
          font-size: 12px;
          font-family: 'Noto Serif JP' ;
          font-weight: 400;
          letter-spacing: 0.08em;
          transform: rotate(-45deg);
        }
        .innerBasic {
          padding: 0 30px;
          h4 {
            margin-bottom: 20px;
            padding-top: 80px;
            background-image: url(../img/contents/service/icon_13.png);
            background-position: center top;
            background-repeat: no-repeat;
            background-size: 60px auto;
            font-size: 20px;
            font-weight: bold;
            text-align: center;
            color:#b89447;
          }
          dl {
            margin-bottom: 5px;
            font-size: 0;
            dt,
            dd {
              display: inline-block;
              vertical-align: top;
            }
            dt {
              width: 35px;
              height: 72px;
              padding-top: 15px;
              font-family: 'Lato', sans-serif;
              font-size: 14px;
              font-weight: 900;
              font-style: italic;
              text-align: center;
              color:#b89447;
            }
            dd {
              position: relative;
              margin-left: 38px;
              padding-left: 74px;
              width:calc(100% - 75px);
              height: 72px;
              background-size: auto 44px;
              background-repeat: no-repeat;
              background-color: #fff;
              font-size: 14px;
              line-height: 72px;
              &:before {
                position: absolute;
                content: '';
                top: 0;
                bottom: 0;
                left:-30px;
                margin: auto;
                width: 30px;
                height: 1px;
                background-color: #b89447;
              }
              &:after {
                position: absolute;
                top:0;
                bottom: 0;
                right: 15px;
                margin: auto;
                content: '●ご承諾書類';
                font-size: 10px;
                color: #888;
                text-align: right;
                letter-spacing: 0;
              }
              &.none {
                &:after {
                  content: '';
                }
              }
            }
            &:nth-of-type(1) {
              margin-top: 30px;
              dd {
                background-image: url(../img/contents/service/icon_p01.png);
                background-position: 14px center;
              }
            }
            &:nth-of-type(2) {
              dd {
                background-image: url(../img/contents/service/icon_p02.png);
                background-position: 13px center;
              }
            }
            &:nth-of-type(3) {
              dd {
                background-image: url(../img/contents/service/icon_p03.png);
                background-position: 16px center;
              }
            }
            &:nth-of-type(4) {
              dd {
                background-image: url(../img/contents/service/icon_p04.png);
                background-position: 13px center;
              }
            }
            &:nth-of-type(5) {
              dd {
                background-image: url(../img/contents/service/icon_p05.png);
                background-position: 13px center;
              }
            }
            &:nth-of-type(6) {
              margin-bottom: 20px;
              dd {
                background-image: url(../img/contents/service/icon_p06.png);
                background-position: 13px center;
              }
            }
          }
          p {
            &.notes {
              font-size: 12px;
              color: #888;
            }
            &.btn {
              margin-top: 40px;
              a {
                position: relative;
                display: block;
                margin: 0 auto;
                width: 200px;
                height: 50px;
                background-color: #b89447;
                text-align: center;
                text-decoration: none;
                font-family: 'Lato', sans-serif;
                font-size: 10px;
                font-weight: 900;
                letter-spacing: 0.14em;
                color: #fff;
                span {
                  line-height: 50px;
                }
                &:after {
                  position: absolute;
                  content: '';
                  top: 0;
                  bottom: 0;
                  right: 15px;
                  margin: auto;
                  width: 8px;
                  height: 12px;
                  background-image: url(../img/contents/arrow_01.png);
                  background-size: 8px;
                }
              }
            }
          }
        }
      }
    }
  }


  #PageService.pageIndex {
    #MainImg {
      .image {
        &_img { background-image: url(../img/contents/service/mainimg.jpg); }
      }
    }
    #ContBox01 {
      .innerBasic {
        padding: 0 15px;
        ul {
          width: 100%;
          li {
            margin-bottom: 15px;
            width: 100%;
            border: #dd2a23 solid 1px;
            a {
              position: relative;
              display: block;
              width: 100%;
              height: 178px;
              padding-top: 115px;
              text-align: center;
              text-decoration: none;
              &:before {
                position: absolute;
                content: '';
                right: 0;
                left: 0;
                margin: auto;
                width: 120px;
                height: 120px;
                background-repeat: no-repeat;
                background-position: center top;
                }
              span {
                display: inline-block;
                font-size:18px;
                line-height: 18px;
                vertical-align: middle;
                &:after {
                display: inline-block;
                content: '';
                width: 13px;
                height: 18px;
                margin-left: 14px;
                background-image: url(../img/contents/arrow_02.png);
                background-repeat: no-repeat;
                background-size: 12px auto;
                background-position: center;
                vertical-align: top;
                }
              }
            }
          &:nth-of-type(1) {
            a {
              &:before {
                top :40px;
                background-image: url(../img/contents/service/icon_01.png);
                background-size: 54px auto;
              }
            }
          }
          &:nth-of-type(2) {
            a {
              &:before {
                top :35px;
                background-image: url(../img/contents/service/icon_02.png);
                background-size: 51px auto;
              }
            }
          }
          &:nth-of-type(3) {
            a {
              &:before {
                top :40px;
                background-image: url(../img/contents/service/icon_03.png);
                background-size: 72px auto;
              }
            }
          }
          &:nth-of-type(4) {
            position: relative;
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: '';
                width: 0;
                border-width: 80px 80px 0px 0px;
                border-style: solid;
                border-color: #b89447 #fff #fff #b89447;
              }
              &:after {
                position: absolute;
                top: 22px;
                left: 0;
                content: 'PREMIUM';
                color: #fff;
                font-size: 12px;
                font-family: 'Noto Serif JP' ;
                font-weight: 400;
                letter-spacing: 0.08em;
                transform: rotate(-45deg);
              }
            a {
              &:before {
                top :30px;
                background-image: url(../img/contents/service/icon_04.png);
                background-size: 60px auto;
              }
            }
          }
          }
        }
        .bnrBox {
          width: 100%;
          padding-top: 40px;
          img {
            width: 100%;
          }
        }
      }
    }
  }


  #PageService.pageNewhp,
  #PageService.pageRenewhp,
  #PageService.pageRecruit,
  #PageService.pagePremium,
  #PageService.pageResponsive,
  #PageService.pagePhotograph,
  #PageService.pageConsulting,
  #PageService.pageSeo,
  #PageService.pageCms,
  #PageService.pageListing,
  #PageService.pageManagement,
  #PageService.pageAnalytics,
  #PageService.pageLogo,
  #PageService.pagePrint {
    #MainImg {
      margin-bottom: 70px;
      #MainImgInner {
        padding: 0;
        height: 160px;
        .innerBg {
          margin-left: 0;
          width: 100%;
          height: 100%;
        }
        .title {
          width: 100%;
          height: 100%;
          top: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          .icon {
            display: block;
            margin: 0;
            margin-bottom: 3px;
            background-position: center center;
            background-repeat: no-repeat;
            transform: translateY(-6px);
          }
          h2 {
            margin: 0;
            text-align: center;
            font-size: 20px;
            line-height: 1.4;
            letter-spacing: .05em;
            color: #000;
            font-weight: 900;
          }
        }
      }
    }
    #ContBox01 {
      h3 {
        margin-bottom: 35px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #dd2b23;
      }
      #ContSubBox01 {
        position: relative;
        padding-top: 90px;
        background-color: #f7f7f7;
        overflow: hidden;
        .bgRedBox {
          position: absolute;
          z-index: 10;
          top: 0;
          left: 0;
          width: 100%;
          height: 242px;
          &:after {
            position: absolute;
            top:0;
            left: 30px;
            content: '';
            width:calc(100% - 30px);
            height: 242px;
            background-size: cover;
          }
        }
        .bgWhiteBox {
          position: absolute;
          z-index: 11;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 60px;
          background-color: #fff;
        }
        .contentsBox {
          position: relative;
          padding-top: 70px;
          padding-bottom: 45px;
          width: calc(100% - 45px);
          background-color: #fff;
          z-index: 13;
          h4 {
            position: absolute;
            padding-right: 40px;
            top:25px;
            right: 0;
            font-family: 'Lato', sans-serif;
            font-size: 14px;
            font-weight: 900;
            font-style: italic;
            text-transform:uppercase;
            &:after {
              position: absolute;
              content: '';
              top:0;
              bottom: 0;
              right: 0;
              margin: auto;
              width: 30px;
              height: 1px;
              background-color: #000;
            }
          }
          &:after {
            position: absolute;
            top: 180px;
            right: -53px;
            writing-mode: vertical-rl;
            font-family: 'Lato', sans-serif;
            font-size: 60px;
            font-weight: 900;
            font-style: italic;
            line-height: 1em;
            letter-spacing: 0.05em;
            white-space: nowrap;
            text-transform:uppercase;
            color: #fff;

          }
          .innerBasic {
            width: 100%;
            padding: 0 30px;
          }
          dl {
            margin: 0 auto;
            width: 100%;
            dt {
              margin-bottom: 30px;
              font-size: 18px;
              font-weight: bold;
              span {
                display: block;
                font-family: 'Lato', sans-serif;
                font-size: 26px;
                font-weight: 900;
                color: #dd2b23;
              }
            }
            dd {
              margin-bottom: 40px;
              padding-bottom: 40px;
              border-bottom: #e5e5e5 solid 1px;
              font-size: 14px;
              line-height: 1.75;
              text-align: justify;
              span {
                color:#888;
              }
              &:last-of-type {
                border-bottom: none;
              }
            }
          }
        }
      }
    }
  }

  #PageService.pageNewhp {
    #MainImg {
      .title {
        .icon {
          width: 54px;
          height: 54px;
          background-image: url(../img/contents/service/icon_01.png);
          background-size: 54px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_01.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'new homepage';
          }
        }
      }
    }
  }
  #PageService.pageRenewhp {
    #MainImg {
      .title {
        .icon {
          width: 51px;
          height: 65px;
          background-image: url(../img/contents/service/icon_02.png);
          background-size: 51px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_02.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'renewal';
          }
        }
      }
    }
  }
  #PageService.pageCms {
    #MainImg {
      .title {
        .icon {
          width: 64px;
          height: 64px;
          background-image: url(../img/contents/service/icon_08.png);
          background-size: 64px auto;
        }
      }
    }
  }
  #PageService.pageListing {
    #MainImg {
      .title {
        .icon {
          width: 60px;
          height: 60px;
          background-image: url(../img/contents/service/icon_11.png);
          background-size: 60px auto;
        }
      }
    }
  }
  #PageService.pageAnalytics {
    #MainImg {
      .title {
        .icon {
          width: 60px;
          height: 60px;
          background-image: url(../img/contents/service/icon_07.png);
          background-size: 60px auto;
        }
      }
    }
  }
  #PageService.pageRecruit {
    #MainImg {
      .title {
        .icon {
          width: 72px;
          height: 54px;
          background-image: url(../img/contents/service/icon_03.png);
          background-size: 72px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_03.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'recruit';
          }
        }
      }
    }
  }
  #PageService.pageResponsive {
    #MainImg {
      .title {
        .icon {
          width: 52px;
          height: 64px;
          background-image: url(../img/contents/service/icon_05.png);
          background-size: 52px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_05.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'RESPONSIVE';
          }
        }
      }
    }
  }
  #PageService.pagePhotograph {
    #MainImg {
      .title {
        .icon {
          width: 58px;
          height: 64px;
          background-image: url(../img/contents/service/icon_06.png);
          background-size: 58px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_06.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'PHOTOGRAPH';
          }
        }
      }
    }
    #VoiceBox {
      .serviceVoice {
        .innerBox {
          dl {
            position: relative;
            dd.name {
              position: absolute;
              bottom: 87px;
              left: 0;
              right: 0;
              margin: auto;
              color: #dd2b23;
              font-weight: bold;
              font-family: 'Lato', sans-serif;
              font-size: 24px;
              font-style: italic;
            }
          }
          .nonSpec {
            padding-bottom: 30px;
          }
        }
      }
    }
  }
  #PageService.pageListing {
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_11.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'listing';
          }
        }
      }
    }
  }
  #PageService.pageAnalytics {
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_07.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'Analytics';
          }
        }
      }
    }
  }
  #PageService.pageCms {
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_08.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'CMS';
          }
        }
      }
    }
  }
  #PageService.pageConsulting {
    #MainImg {
      .title {
        .icon {
          width: 64px;
          height: 64px;
          background-image: url(../img/contents/service/icon_10.png);
          background-size: 64px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_10.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'consulting';
          }
        }
      }
    }
  }
  #PageService.pageManagement {
    #MainImg {
      .title {
        .icon {
          width: 60px;
          height: 60px;
          background-image: url(../img/contents/service/icon_12.png);
          background-size: 60px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_12.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'management';
          }
        }
      }
    }
  }
  #PageService.pageSeo {
    #MainImg {
      .title {
        .icon {
          width: 66px;
          height: 72px;
          background-image: url(../img/contents/service/icon_09.png);
          background-size: 66px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_09.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'SEO';
          }
        }
      }
    }
  }
  #PageService.pageLogo {
    #MainImg {
      .title {
        .icon {
          width: 77px;
          height: 42px;
          background-image: url(../img/contents/service/icon_14.png);
          background-size: 77px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_13.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'LOGO';
          }
        }
      }
    }
  }
  #PageService.pagePrint {
    #MainImg {
      .title {
        .icon {
          width: 57px;
          height: 52px;
          background-image: url(../img/contents/service/icon_15.png);
          background-size: 57px auto;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        .bgRedBox {
          &:after {
            background-image:url(../img/contents/service/title_14.jpg);
          }
        }
        .contentsBox {
          &:after {
            content: 'desktop publishing';
          }
        }
      }
    }
  }
  #PageService.pagePremium {
    #MainImg {
      #MainImgInner {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 0;
          border-width: 70px 70px 0px 0px;
          border-style: solid;
          border-color: #b89447 #fbf5e9 #fbf5e9 #b89447;
        }
        &:after {
          position: absolute;
          top: 19px;
          left: 0;
          content: 'PREMIUM';
          color: #fff;
          font-size: 10px;
          font-family: 'Noto Serif JP' ;
          font-weight: 400;
          letter-spacing: 0.08em;
          transform: rotate(-45deg);
        }
      }
      .innerBg {
        background-image: none;
        background-color: #fbf5e9;
      }
      .title {
        .icon {
          width: 60px;
          height: 74px;
          background-image: url(../img/contents/service/icon_13.png);
          background-size: 60px auto;
        }
      }
    }
    #ContBox01 {
      h3 {
        color: #b89447;
      }
      #ContSubBox01 {
        .bgRedBox {
          background-color: #b89447;
          &:after {
            background-image:url(../img/contents/service/title_04.jpg);
          }
        }
        .contentsBox {
          padding-bottom: 1px;
          &:after {
            content: 'premium';
          }
          dl {
            dt {
              span {
                color: #b89447;
              }
            }
          }
        }
      }
    }
    #PremiumBox {
      padding-top: 0;
      .contSubBox {
        &:before,
        &:after {
          content: none;
        }
      }
    }

    #ContBox02 {
      position: relative;
      padding-bottom: 1px;
      background-color: #fbf5e9;
      .contSubBox {
        position: relative;
        padding-top: 175px;
        margin-bottom: 60px;
        .contentsBox {
          position: relative;
          margin: 0 15px 0 30px;
          padding: 30px;
          background-color: #fff;
          z-index: 13;
          b {
            position: relative;
            font-family: 'Lato', sans-serif;
            font-weight: 900;
            font-size: 14px;
            font-style: italic;
            letter-spacing: 0.05em;
            line-height: 1em;
            color: #b89447;
          }
          h4 {
            position: relative;
            margin-top: 40px;
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
            &:before {
              content: "";
              position: absolute;
              left:0;
              background-position: left bottom;
              background-repeat: no-repeat;
            }
          }
          p,li {
            font-size: 14px;
            letter-spacing: 0.08em;
            line-height: 26px;
            margin-bottom: 25px;
          }
          ul {
            margin-top: -10px;
            margin-bottom: 23px;
            li {
              margin-bottom: 2px;
              margin-left: 0.9em;
              text-indent: -0.9em;
              &:before {
                content: "・ ";
              }
            }
          }
          ol {
            margin-top: -10px;
            margin-bottom: 30px;
            li{
              margin-bottom: 2px;
              margin-left: 1.2em;
              text-indent: -1.2em;
              span {
                color: #888888;
              }
            }
          }
          figure {
            margin-top: 40px;
            padding-bottom: 10px;
            img {
              width: 100%;
            }
          }
        }
        .bgRedBox {
          position: absolute;
          top: 45px;
          height: 220px;
          width: 100% ;
          background-color: #b89447;
          z-index: 11;
          &:before {
            content: "";
            position: absolute;
            top: -45px;
            left: 0px;
            width: calc(100% - 15px);
            height: 100%;
            background-size: cover;
            background-position: center;
          }
        }
      }
      #ContSubBox01 {
        .contentsBox {
          padding-bottom: 5px;
          h4 {
            padding-left: 58px;
            &:before {
              top: -20px;
              background-image: url(../img/contents/service/icon_p01.png);
              background-size:35px auto;
              width: 35px;
              height: 44px;
            }
          }
        }
        .bgRedBox {
          &:before {
            background-image: url(../img/contents/service/premium_img01.jpg);
          }
        }
      }
      #ContSubBox02 {
        .contentsBox {
          margin: 0 30px 0 15px;
          h4 {
            padding-left: 55px;
            &:before {
              top: -13px;
              background-image: url(../img/contents/service/icon_p02.png);
              background-size:41px auto;
              width: 41px;
              height: 36px;
            }
          }
        }
        .bgRedBox {
          &:before {
            left: 15px;
            background-image: url(../img/contents/service/premium_img03.jpg);
          }
        }
      }
      #ContSubBox03 {
        .contentsBox {
          h4 {
            padding-left: 53px;
            &:before {
              top: -13px;
              background-image: url(../img/contents/service/icon_p03.png);
              background-size:36px auto;
              width: 36px;
              height: 38px;
            }
          }
        }
        .bgRedBox {
          &:before {
            background-image: url(../img/contents/service/premium_img04.jpg);
          }
        }
      }
      #ContSubBox04 {
        .contentsBox {
          margin: 0 30px 0 15px;
          h4 {
            padding-left: 57px;
            &:before {
              top: -17px;
              background-image: url(../img/contents/service/icon_p04.png);
              background-size:42px auto;
              width: 42px;
              height: 42px;
            }
          }
        }
        .bgRedBox {
          &:before {
            left: 15px;
            background-image: url(../img/contents/service/premium_img06.jpg);
          }
        }
      }
      #ContSubBox05 {
        .contentsBox {
          h4 {
            padding-left: 55px;
            &:before {
              top: -9px;
              background-image: url(../img/contents/service/icon_p05.png);
              background-size:38px auto;
              width: 38px;
              height: 33px;
            }
          }
        }
        .bgRedBox {
          &:before {
            background-image: url(../img/contents/service/premium_img07.jpg);
          }
        }
      }
      #ContSubBox06 {
        .contentsBox {
          margin: 0 30px 0 15px;
          h4 {
            padding-left: 56px;
            &:before {
              top: -19px;
              background-image: url(../img/contents/service/icon_p06.png);
              background-size:36px auto;
              width: 36px;
              height: 42px;
            }
          }
        }
        .bgRedBox {
          &:before {
            left: 15px;
            background-image: url(../img/contents/service/premium_img08.jpg);
          }
        }
      }

    }

  }

/*#################### pageUniversity（LP） ####################*/
@keyframes bgiLoop {
    0% { background-position: 0 0;}
  100% { background-position: -1073px 0px;}
}
  #PageService.pageUniversity {
    .disp_1360 {
      display: none;
    }
    #MainImg {
      margin-bottom: 0;
      #MainImgInner01 {
        overflow: hidden;
        position: relative;
        background-image: url(../img/contents/service/university/wall11.png);
        background-repeat: no-repeat;
        background-position: right -30px bottom 5px;
        background-size: 240px auto;
        background-color: #DD2B23;
        height: 623px;
        padding-top: 15px;
        #Slider {
          width: 100%;
          height: 309px;
          background-image: url(../img/contents/service/university/slider.png);
          background-size: auto 309px;
          animation: bgiLoop 16s linear infinite;
        }
        #Label {
          position: absolute;
          top: 182px;
          right: -20px;
          width: 176px;
          height: 175px;
          font-size: 0;
          background-image: url(../img/contents/service/university/wall10.png);
          background-size: 176px auto;
          background-position: left top;
        }
        h2 {
          margin-top: 22px;
          margin-left: 15px;
          text-align: left;
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 0.065em;
          line-height: 1em;
          color: #ffffff;
          span {
            font-size: 23px;
            color: #ffffff;
          }
          b {
            display: inline-block;
            padding:19px 18px 23px 18px;
            margin-top: 10px;
            margin-right: 10px;
            background-color: #fff;
            font-size: 52px;
            letter-spacing: 0.07em;
            color: #DD2B23;
          }
        }
      }
      #MainImgInner02 {
        background-color: #FFEB63;
        height: 215px;
        .innerBox {
          position: relative;
          h3 {
            position: absolute;
            top: -20px;
            left: 0;
            right: 0;
            margin: auto;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: 0.13em;
            b {
              font-size: 20px;
            }
            span {
              display: block;
              margin: 0 auto;
              margin-bottom: 5px;
              width: 255px;
              height: 40px;
              padding-top: 2px;
              border-radius: 20px;
              background-color: #000000;
              font-size: 23px;
              text-align: center;
              letter-spacing: 0.15em;
              color: #ffffff;
            }
          }
          div {
            display: flex;
            justify-content:center;
            align-items:center;
            padding-top: 70px;
            p {
              margin-bottom: 0;
              font-size: 28px;
              font-weight: bold;
            }
            p:first-of-type {
              margin-right: 5px;
              padding-top: 5px;
              line-height: 1.3;
              color: #DD2B23;
            }
            p:last-of-type {
             // display: block;
             padding-top: 92px;
             padding-bottom: 10px;
             padding-left: 115px;
              background-image: url(../img/contents/service/university/wall09.png);
                background-size: 170px auto;
                background-repeat: no-repeat;
                background-position: left top;
              span {
                display:none;

              }
            }
          }
        }
      }
      #MainImgInner03 {
        padding-top: 20px;
        padding-bottom: 20px;
        background-color: #ffffff;
        h4 {
          position: relative;
          width: 100%;
          max-width: 390px;
          margin: 0 auto;
         // display: flex;
         // justify-content: center;
         // align-items: center;
          margin-bottom: 19px;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0.14em;
          line-height: 1.4;
          &:before,
          &:after {
            position: absolute;
            content: "";
            width: 51px;
            height: 1px;
            background-color: #000;
          }
          &:before {
            left: 0;
            bottom: 20px;
            transform: rotate(60deg);
          }
          &:after {
            right: 0;
            bottom: 20px;
            transform: rotate(-60deg);
          }
        }
        ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          li {
            display: block;
            margin: 0 6px 11px 6px;
            padding-top: 64px;
            width: 112px;
            height: 95px;
            border: 1px solid #B9B9B9;
            background-size: 49px auto;
            background-repeat: no-repeat;
            background-position: center 15px;
            box-shadow: 6px 6px 0px 0px rgba(0,0,0,0.16);
            font-size: 16px;
            text-align: center;
            &.list01 {
              background-image: url(../img/contents/service/university/icon14.png);
            }
            &.list02 {
              background-image: url(../img/contents/service/university/icon15.png);
            }
            &.list03 {
              background-image: url(../img/contents/service/university/icon16.png);
            }
            &.list04 {
              background-image: url(../img/contents/service/university/icon17.png);
            }
            &.list05 {
              background-image: url(../img/contents/service/university/icon18.png);
            }
          }
        }
      }
      #MainImgInner04 {
        padding: 60px 20px 25px 20px;
        .innerBox {
          position: relative;
          padding: 35px 20px;
          margin: 0 auto;
          background-color: #F5F5F5;
          width: 100%;
          max-width: 520px;
          &::before {
            content: "";
            position: absolute;
            top: -22px;
            left: 0;
            right: 0;
            margin: auto;
            width: 44px;
            height: 44px;
            background-image: url(../img/contents/service/university/icon21.png);
          }
          h3 {
            margin-bottom: 10px;
            font-weight: bold;
            font-size: 20px;
            color: #DD2B23;
          }
          ul {
            margin-left: 10px;
            li {
              position: relative;
              margin-bottom: 5px;
              padding-left: 13px;
              text-align: left;
              &::before {
                content: "";
                position: absolute;
                top: .6em;
                left: 0;
                width: 7px;
                height: 7px;
                background-color: #BCBCBC;
                border-radius: 50%;
              }
            }
          }
        }
      }
    }
    #Main {
      #ContBox01 {
        position: relative;
        padding: 90px 15px 60px 15px;
        text-align: center;
        background-color: #f7f7f7;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #DD2B23;
        }
        p {
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: bold;
          text-align: center;
          letter-spacing: .2em;
          line-height: 1.3;
          color: #DD2B23;
        }
        h3 {
          position: relative;
          display: inline-block;
          margin: 0 auto;
          transform: translate(-10px, -4px);
          line-height: 1;
          letter-spacing: .05em;
          font-family: "Lato", sans-serif;
          font-weight: 900;
          font-size: 44px;
          &:after {
            content: "";
            position: absolute;
            background: url(../img/contents/top/icon_01.png) top left no-repeat;
            background-size: contain;
            width: 17px;
            height: 33px;
            transform: translate(4px, 5px);
          }
        }
        small {
          display: block;
          margin-top: 1px;
          line-height: 1;
          letter-spacing: 0em;
          font-size: 14px;
          color: #888;
        }
        .contSubBox {
          padding-top: 40px;
        div {
          margin-bottom: 30px;
          text-align: left;
          img {
            margin-bottom: 10px;
            width: 100%;
            border: solid 1px #BCBCBC;
          }
          p {
            margin-bottom: 10px;
            font-size: 16px;
            color: #000;
            font-weight:normal;
            letter-spacing: 0;
            text-align: left;
          }
          .labelList .label {
          display: inline-block;
          padding: 3px 7px 5px;
          background-color: #dd2b23;
          line-height: 1;
          letter-spacing: 0;
          font-size: 12px;
          color: #fff;
          }
        }
        }
        .moreBtn {
          display: inline-block;
          background: url(../img/contents/arrow_01.png) no-repeat;
          background-color: #dd2b23;
          text-decoration: none;
          color: #fff;
          margin: 0 auto;
          width: 260px;
          height: 60px;
          background-position: center right 34px;
          background-size: 8px 12px;
          line-height: 60px;
          font-size: 12px;
          span {
            display: inline-block;
            margin-left: -1px;
            font-family: "Lato", sans-serif;
            font-weight: 900;
            &:first-of-type {
              margin-left: 3px;
          }
        }
      }
      }
      #ContBox02 {
        position: relative;
        padding-top: 90px;
        &:before {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #000;
        }
        h3 {
          text-align: center;
          font-weight: bold;
          font-size: 28px;
          color: #DD2B23;
          span {
            font-size: 20px;
            color: #000;
          }
        }
        .contSubBox {
          margin-top: 80px;
          position: relative;
          padding-top: 30px;
          .bgRedBox {
            position: absolute;
            top: 0;
            width: 100%;
            height: 30px;
            background-color: #DD2B23;
          }
          .contentsBox {
            position: relative;
            padding-top: 30px;
            //position: absolute;
            //top: 30px;
            background-image: url(../img/contents/service/university/wall03.png);
            background-size: 435px auto;
            background-repeat: no-repeat;
            background-position: center top;
            background-color: #F5F5F5;
            b {
              position: absolute;
              top: -85px;
              left: 0;
              right: 0;
              margin: auto;
              text-align: center;
              font-family: "Lato", sans-serif;
              font-weight: 900;
              font-style: italic;
              font-size: 30px;
              color: #A3A3A3;
            }
            .textArea {
              margin: 0 auto;
              padding: 0 35px;
              width: 100%;
              max-width: 650px;
            }
            h4 {
              margin-bottom: 20px;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0.15em;
              span {
                letter-spacing: 0.1em;
                color: #DD2B23;
              }
            }
            p {
              margin: 0;
              font-size: 16px;
              line-height: 2;
            }
            figure {
              margin: 0 auto;
              margin-top: 30px;
              text-align: center;
              img {
                width: 100%;
                //width: 390px;
              }
            }
          }
        }
        #ContSubBox01 {
          margin-top: 90px;
          figure {
            margin-top: 0;
          }
        }
        #ContSubBox05 {
          .textArea {
            div.works {
              margin-top: 20px;
              text-align: center;
              img {
                margin-bottom: 10px;
                width: 80%;
              }
              p {
                text-align: center;
                font-size: 14px;
                line-height: 1.4;
                a {
                  display: inline-block;
                  font-family: "Lato", sans-serif;
                  text-decoration: none;
                  &:after {
                    content: "";
                    display: inline-block;
                    margin-left: 6px;
                    width: 13px;
                    height: 11px;
                    background: url(../img/contents/icon_link.png) no-repeat center;
                    background-size: 13px auto;
                    transform: translate(-2px, 2px);
                  }
                }
              }
            }
          }
        }
    }
    #ContBox03 {
      padding: 50px 15px;
      background-color: #F5F5F5;
      h3 {
        margin-bottom: 40px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        letter-spacing: .1em;
        line-height: 1.3;
        color: #DD2B23;
        span {
          display: block;
          font-size: 20px;
          letter-spacing: .1em;
          color: #000;
        }
      }
      table {
        margin: 0 auto;
        width: 100%;
        thead {
          border: solid 1px #343434;
          border-bottom: none;
        }
        tbody {
          border: solid 1px #707070;
        }
        tr {
          th {
            padding: 5px 25px;
            height: 50px;
            background-color: #343434;
            font-size: 14px;
            text-align: center;
            vertical-align: middle;
            color: #fff;
            &:first-of-type {
              width: 47%;
              border-right: solid 1px #fff;
            }
          }
          td {
            padding: 13px;
            border-bottom: solid 1px #B9B9B9;
            background-color: #fff;
            font-size: 14px;
            vertical-align: middle;
            &:first-of-type {
              border-right: solid 1px #B9B9B9;
              //border-left: solid 1px #707070;
            }
            &:last-of-type {
              //border-right: solid 1px #707070;
            }
          }
          &:last-of-type {
            td {
              border-bottom:none;
            }
          }
        }
      }
      /*2024.03.18 楊追加start*/
      .tableBox {
        position: relative;
        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 132px;
          background: linear-gradient(rgba(255, 255, 255, 0) 0%, #f5f5f5 100%);
          left: 0;
          bottom: 90px;
        }
        &.active {
          & > table {
            tbody { border-bottom-color: #b9b9b9; }
          }
          &:after { display: none; }
          .click { background-image: url(../img/contents/service/university/icon_20.png); }
        }
      }
      .showBox {
        table {
          tbody { border-top-width: 0; }
          td:first-of-type { width: 47%; }
        }
      }
      .click {
        display: block;
        width: 260px;
        height: 60px;
        background: #fff url(../img/contents/service/university/icon_19.png) no-repeat right 35px center;
        background-size: 22px auto;
        text-align: center;
        margin: 30px auto 0;
        position: relative;
      }
      /*2024.03.18 楊追加end*/
    }
    #ContBox04 {
      padding: 50px 15px 20px 15px;
      background-image: url(../img/contents/service/university/wall02.png);
      background-position: left top;
      background-size: 62% auto;
      //background-size: 240px auto;
      background-repeat: no-repeat;
      background-color: #DD2B23;
      h3 {
        margin: 0 35px;
        margin-bottom: 40px;
        font-weight: bold;
        font-size: 28px;
        text-align: center;
        letter-spacing: 0.08em;
        color: #fff;
        span {
          display: block;
          font-size: 20px;
        }
      }
      .contSubBox {
        dl {
          position: relative;
          margin: 0 auto;
          margin-bottom: 30px;
          width: 100%;
          max-width: 580px;
          background-color: #fff;
          box-shadow: 10px 10px 0px 0px rgba(0,0,0,0.16);
          &:before {
            position: absolute;
            content: "";
            width: 1px;
            height: 30px;
            right: 0;
            left: 0;
            margin: auto;
            background-color: #DD2B23;
          }
          dt {
            padding-top: 50px;
            font-size: 24px;
            font-weight: bold;
            text-align: center;
            color: #DD2B23;
            span {
              color: #000;
            }
            small {
              font-size: 20px;
              color: #000;
            }
            sup {
              font-size: 18px;
            }
          }
          dd {
            padding: 10px 24px 30px 24px;
          }
          &:nth-of-type(2) {
            dd {
              font-size: 12px;
            }
          }
          &:nth-of-type(3) {
            dd {
              text-align: center;
              img {
                margin-top: 10px;
                //width: 312px;
                width: 100%;
                max-width: 450px;
              }
            }
          }
          &:nth-of-type(4) {
            dd {
              text-align: center;
              img {
                margin-top: 10px;
                //width: 265px;
                width: 85%;
                max-width: 470px;
              }
            }
          }
        }
      }
     }
     #ContBox06 {
      padding-top: 46px;
      background-color: #F5F5F5;
      h3 {
        margin-bottom: 35px;
        text-align: center;
        font-size: 28px;
        font-weight: bold;
        line-height: 1.7;
        color: #DD2B23;
        span {
          display: block;
          font-size: 20px;
          color: #000000;
        }
      }
      .contentsBox {
        margin: 0 auto;
        max-width: 1000px;
        background-color: #ffffff;
        h4 {
          margin-bottom: 20px;
          padding: 12px 0;
          background-color: #000000;
          color: #ffffff;
          font-weight: bold;
          font-size: 18px;
          line-height: 1.35;
          text-align: center;
          & + p {
            text-align: center;
            font-size: 16px;
            margin-right: 15px;
            margin-bottom: 50px;
            span {
              display: inline-block;
              border: 1px solid #000;
              padding: 0 12px;
              font-size: 14px;
              &:first-of-type {
                margin: 0 12px 0 5px;
                border: 1px solid #DD2B23;
                color: #DD2B23;
              }
            }
          }
        }
        .contSubBox {
          //margin: 0 15px;
          padding: 0 15px;
          margin-bottom: 35px;
          h5 {
            margin-bottom: 10px;
            padding: 14px 0 20px 0;
            background-color: #DD2B23;
            text-align: center;
            font-weight: bold;
            font-size: 18px;
            line-height: 1em;
            letter-spacing: 0.2em;
            color: #ffffff;
            span {
              font-size: 10px;
              vertical-align: text-top;
            }
            & + p {
              text-align: right;
              font-size: 12px;
            }
          }
          dl {
            padding-left: 56px;
            background-image: url(../img/contents/service/university/wall05.gif);
            background-repeat: repeat-y;
            background-size: 30px auto;
            background-position: left top;
            dt {
              position: relative;
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: bold;
              line-height: 1.8;
              letter-spacing: 0.12em;
              color: #DD2B23;
              b {
                display: block;
                padding: 12px 0;
                margin-bottom: 8px;
                width: 88px;
                height: 40px;
                background-color: #DD2B23;
                text-align: center;
                font-size: 16px;
                font-family: 'Lato', sans-serif;
                font-weight: 900;
                letter-spacing: 0;
                line-height: 1em;
                color: #ffffff;
              }
              span {
                display: inline-block;
                padding: 0 9px;
                margin-right: 5px;
                height: 27px;
                border: 1px solid #DD2B23;
                font-weight: normal;
                font-size: 14px;
                line-height: 27px;
                letter-spacing: 0.08em;
              }
              &:before {
                position: absolute;
                content: "";
                top: 12px;
                left: -48px;
                width: 43px;
                height: 14px;
                background-image: url(../img/contents/service/university/wall04.png);
                background-size: auto 14px;
              }
            }
            dd {
              padding-bottom: 40px;
              p {
                margin: 0;
                line-height: 1.4;
                letter-spacing: 0.05em;
                font-size: 16px;
              }
              div {
                position: relative;
                margin-top: 28px;
                background-color: #F2F2F2;
                padding-bottom: 20px;
                font-size: 14px;
                strong {
                  display: block;
                  padding: 14px 0;
                  margin-bottom: 20px;
                  background-color: #343434;
                  border-radius: 6px 6px 0px 0px;
                  text-align: center;
                  font-size: 14px;
                  font-weight: bold;
                  line-height: 1em;
                  color: #ffffff;
                }
                p {
                  margin: 0 20px;
                  font-size: 14px;
                }
                &:before {
                  position: absolute;
                  content: "";
                  top: -14px;
                  left: 0;
                  right: 0;
                  margin: auto;
                  width: 19px;
                  height: 16px;
                  background-image: url(../img/contents/service/university/wall07.png);
                  background-size: 19px auto;
                }
              }
            }
            &:last-of-type {
              background-image: url(../img/contents/service/university/wall06.gif),url(../img/contents/service/university/wall05.gif);
            background-repeat: no-repeat, repeat-y;
            background-size: 30px auto ,30px auto;
            background-position: left bottom, left top;
            dd {
              padding-bottom: 0px;
            }
            }
          }
        }
        #ContSubBox01 {
          h5 {
            margin-bottom: 20px;
            padding: 19px 0;
          }
        }
        #ContSubBox02 {
          dl:nth-of-type(1) {
            dt {
              span {
                color: #000000;
                border: 1px solid #000000;
              }
            }
          }
        }
        #ContSubBox03 {
          dl:nth-of-type(1) {
            dt {
              span:nth-of-type(2) {
                color: #000000;
                border: 1px solid #000000;
              }
            }
            dd {
              div {
                strong {
                  padding: 12px 0 10px 0;
                  line-height: 1.4;
                }
              }
            }
          }
          dl:last-of-type {
            background-image:url(../img/contents/service/university/wall05.gif);
            background-repeat: repeat-y;
            background-size: 30px auto;
            background-position: left top;
          }
        }
      }
     }
     #ContBox07 {
      position: relative;
      padding: 90px 15px 43px 15px;
      background-color: #FFE4E3;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        margin: auto;
        width: 1px;
        height: 60px;
        background-color: #dd2b23;
      }
      h3 {
        margin-bottom: 40px;
        text-align: center;
          font-weight: bold;
          font-size: 28px;
          letter-spacing: 0.1em;
          small {
            display: block;
            font-size: 20px;
          }
          span {
            color: #DD2B23;
          }
        }
      .contSubBox {
        margin: 0 auto;
        dl {
          position: relative;
          margin: 0 auto;
          margin-bottom: 17px;
          padding: 45px 20px 0 156px;
          min-height: 193px;
          width: 100%;
          max-width: 580px;
          border-top: 4px solid #DD2B23;
          border-bottom: 4px solid #DD2B23;
          box-shadow: 5px 5px 0px 0px rgba(0,0,0,0.16);
          &:before {
            position: absolute;
            content: "";
            width: 46px;
            height: 46px;
            right: 0;
        left: 0;
        margin: auto;
            border-radius: 50%;
            background-image: url(../img/contents/service/university/icon04.png);
            background-size: 24px auto;
            background-position: center;
            background-repeat: no-repeat;
            background-color: #3C3C3C;
          }
          &:nth-of-type(1) {
            background-image: url(../img/contents/service/university/icon01.png);
            background-size: 83px auto;
            background-position: 34px 59px;
            background-repeat: no-repeat;
            background-color: #fff;
            &:before {
              content: none;
            }
          }
          &:nth-of-type(2) {
            padding-top: 37px;
            background-image: url(../img/contents/service/university/icon02.png);
            background-size: 92px auto;
            background-position: 34px 65px;
            background-repeat: no-repeat;
            background-color: #fff;
            &:before {
              top: -38px;
            }
          }
          &:nth-of-type(3) {
            padding-top: 37px;
            background-image: url(../img/contents/service/university/icon03.png);
            background-size: 95px auto;
            background-position: 35px 70px;
            background-repeat: no-repeat;
            background-color: #fff;
            &:before {
              top: -38px;
            }
          }
          dt {
            margin-bottom: 10px;
            font-size: 20px;
            letter-spacing: 0;
            color: #DD2B23;
          }
        }
      }
     }
     #ContBox08 {
      padding: 50px 15px;
      h3 {
        margin-bottom: 40px;
        font-size: 28px;
        font-weight: bold;
        text-align: center;
      }
      h3 + p {
        font-size: 16px;
      }
      p {
          margin-bottom: 10px;
          font-size: 12px;
        }
      table.disp_sptab {
        width: 100%;
        margin-bottom: 28px;
        border-collapse: separate;
        border-spacing: 0px 12px;
        tr {
          //border-bottom: solid 12px #fff;
          th {
            margin-bottom: 12px;
            width: 125px;
            height: 48px;
            font-weight: bold;
            text-align: center;
            vertical-align: middle;
          }
          &:nth-of-type(1) {
            th {
              background-color: #FFDBD9;
            }
          }
          &:nth-of-type(2) {
            th {
              background-color: #F57873;
              color: #fff;
            }
          }
          &:nth-of-type(3) {
            th {
              background-color: #DD2B23;
              color: #fff;
            }
          }
          td {
            padding-left: 12px;
            font-size: 16px;
            vertical-align: middle;
            span {
              color: #DD2B23;
            }
          }
        }
      }
      .contSubBox {
        width: 100%;
        margin-bottom: 50px;
        overflow-x: scroll;
        &::-webkit-scrollbar {
          height:10px;
        }
        &::-webkit-scrollbar-thumb {
          background:#DD2B23;
          border-radius: 5px;
        }
        &::-webkit-scrollbar-track {
          background:#E0E0E0;
          border-radius: 5px;
        }
        table {
          width: 100%;
          min-width: 444px;
          max-width: 875px;
          margin-bottom: 10px;
          thead {
            tr {
              th {
                height: 45px;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;
                &:nth-of-type(2) {
                  background-color: #FFDBD9;
                }
                &:nth-of-type(3) {
                  background-color: #F57873;
              color: #fff;
                }
                &:nth-of-type(4) {
                  background-color: #DD2B23;
              color: #fff;
                }
                div {
                  display: none;
                }
              }
            }
          }
          tbody {
            tr {
              th {
                //width: 108px;
                width: 24.5%;
                height: 65px;
                background-color: #000;
                color: #fff;
                border-bottom: solid 1px #B9B9B9;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;
              }
              td {
                //width: 112px;
                width: 25.2%;
                background-color: #F8F8F8;
                border-bottom: solid 1px #B9B9B9;
                border-right: solid 1px #B9B9B9;
                text-align: center;
                vertical-align: middle;
                font-size: 14px;
                font-weight: bold;
                span {
                  font-size: 18px;
                  font-family: "Lato", sans-serif;
                  color: #DD2B23;
                }
                &:last-of-type {
                  border-right: none;
                }
              }
              &:last-of-type {
                  th,td {
                    border-bottom: none;
                  }
              }
            }
          }
        }
      }
      table.aboutLp {
        width: 100%;
        max-width: 503px;
        margin-bottom: 10px;
          tr {
            th {
              width: 57%;
              max-width: 253px;
              height: 54px;
              padding: 7px 18px;
              background-color: #000;
              vertical-align: middle;
              font-size: 14px;
              color: #fff;
            }
            td {
              padding: 0 18px;
              background-color: #F8F8F8;
              vertical-align: middle;
              font-size: 14px;
              font-weight: bold;
              span {
                  font-size: 18px;
                  font-family: "Lato", sans-serif;
                  color: #DD2B23;
                }
            }
          }
      }
     }
     #ContBox09 {
      background-color: #FFE1E0;
      padding: 0 25px 30px 25px;
      div.bgWhiteBox {
        height: 60px;
        width: 290px;
        margin: 0 auto;
        margin-bottom: 10px;
        background-color: #fff;
        clip-path: polygon(0 0, 100% 0, 50% 100%);
      }
      h3 {
        margin-bottom: 20px;
        text-align: center;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0.1em;
        sup {
          font-size: 14px;
        }
        span {
          color: #DD2B23;
        }
      }
      .contSubBox {
        max-width: 580px;
        margin: 0 auto;
      }
      ol {
        list-style: none;
        margin-right: 27px;
        li {
          position: relative;
          margin-bottom: 45px;
          padding-left: 5px;
          border-radius: 18px;
          background-size: 44px auto;
          background-position: 20px center;
          background-repeat: no-repeat;
          text-align: center;
          font-size: 18px;
          font-weight: bold;
          line-height: 66px;
          color: #fff;
          &:before {
            position: absolute;
            content: "";
            top: -35px;
            right: 0;
            left: 0;
            margin: auto;
            width: 24px;
            height: 24px;
            background-image: url(../img/contents/service/university/icon10.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 24px auto;
          }
          &:after {
            position: absolute;
            content: "";
            top: -6px;
            right: -27px;
            width: 78px;
            height: 78px;
            border: solid 1px #DD2B23;
            border-radius: 50%;
            background-image: url(../img/contents/service/university/img07.png);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 50px auto;
            background-color: #fff;
          }
          &:nth-of-type(1) {
            background-image: url(../img/contents/service/university/icon05.png);
            background-color: #DD2B23;
            &:before {
              content: none;
            }
          }
          &:nth-of-type(2) {
            background-image: url(../img/contents/service/university/icon06.png);
            background-color: #DD2B23;
          }
          &:nth-of-type(3) {
            background-image: url(../img/contents/service/university/icon07.png);
            background-color: #DD2B23;
          }
          &:nth-of-type(4) {
            background-image: url(../img/contents/service/university/icon08.png);
            background-color: #DD2B23;
          }
          &:nth-of-type(5) {
            background-image: url(../img/contents/service/university/icon09.png);
            background-color: #DD2B23;
            margin-bottom: 15px;
          }
        }
      }
      p {
        font-size: 12px;
        text-align: right;
      }
     }
     #VoiceBox {
      padding-top: 0;
      h3 {
        font-size: 28px;
        color: #DD2B23;
        small {
          font-size: 20px;
          color: #000;
        }
      }
     }
    .contactBox {
        padding-top: 50px;
        padding-bottom: 50px;
        width: 100%;
    .innerBasic {
      padding: 0 30px;
      h3 {
        margin: 0;
        margin-bottom: 15px;
        font-size: 20px;
        font-weight: bold;
        line-height: 20px;
        letter-spacing: 0.14em;
        text-align: center;
        color: #DD2B23;
        span {
          color: #000;
        }
      }
      .linkBox {
        position: relative;
        margin: 0 auto;
        max-width: 1160px;
        &:after {
            position: absolute;
            content: "";
            top:0;
            bottom: 0;
            margin: auto;
            right: 13px;
            width: 10px;
            height: 15px;
            background-position: center;
            background-image: url(../img/contents/arrow_01.png);
            background-color: #dd2a23;
            background-size: 10px auto;
        }
        a {
          position: relative;
            display: block;
            padding-top: 18px;
            width: 100%;
            height: 90px;
            background-color: #dd2b23;
            text-align: center;
            color: #fff;
            text-decoration: none;
            letter-spacing: 0.1em;
          font-size: 10px;
          strong {
            display: block;
              font-family: 'Lato', sans-serif;
              font-weight: 900;
              font-size: 20px;
            letter-spacing: 0.08em;
            &:after {
              display: inline-block;
                content: '';
                width: 12px;
                height: 24px;
                background-image:url(../img/footer/icon_mark.png);
                background-size: contain;
                vertical-align: baseline;
            }
          }
          .linkText {
            display: none;
          }
        }
      }
    }
  }

    }
  }




/*#################### Process ####################*/
  #PageProcess.pageIndex {
    #MainImg {
      margin-bottom: 70px;
      .image {
        &_img { background-image: url(../img/contents/process/mainimg.jpg); }
      }
    }
    #Main {
      .contBox {
        padding-top: 30px;
        padding-bottom: 75px;
        background-color: #fff;
        .innerBasic {
          h3 {
            position: relative;
            padding-top: 70px;
            margin-bottom: 40px;
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            span {
              display: block;
              font-size: 14px;
              font-family: 'Lato', sans-serif;
              font-weight: 900;
              font-style: italic;
              text-transform:uppercase;
            }
            &:before {
              position: absolute;
              content:'';
              top: 0;
              width: 1px;
              height: 60px;
              background-color: #000;
            }
          }
          .contSubBox {
            position: relative;
            overflow: hidden;
            .contentsBox {
              position: relative;
              width: calc( 100% - 45px);
              margin-top: -30px;
              margin-left: 45px;
              padding: 40px 30px 10px;
              background-color: #fff;
              z-index: 12;
              p {
                font-size: 14px;
                span {
                  color: #888;
                }
                &.notes {
                  font-size: 12px;
                }
              }
            }
            .bgImgBox {
              position: relative;
              z-index: 11;
              img {
                width: 100%;
              }
            }
            .bgRedBox{
              position: absolute;
              top: 0;
              margin-left: 15px;
              width: calc( 100% - 15px);
              height: 100%;
              background-color: #dd2b23;
              z-index: 10;
            }
          }
        }
        &:nth-child(even) {
          background-color: #f7f7f7;
          .innerBasic {
            .contSubBox {
              .contentsBox {
                margin-left: 0;
                margin-right: 45px;
                background-color: #f7f7f7;
              }
              .bgRedBox {
                margin: auto;
                margin-right: 15px;
              }
            }
          }
        }
      }
      #ContBox07 {
        .innerBasic h3 {
          color: #dd2b23;
          &:before {
            background-color: #dd2b23;
          }
        }
      }

      #ContBox09 {
        padding-bottom: 20px;
        background-color: #ebebeb;
        p {
          text-align: center;
          font-size: 12px;
          color: #dd2b23;
        }
      }
    }
  }

/*#################### Works ####################*/
  #PageWorks {
    .innerBasic { max-width: 100%; }
    #Main {
      .innerBasic { max-width: 1280px; }
      #SearchBox {
        background-color: #f7f7f7;
        padding: 15px;
        margin-bottom: 45px;
        .pikcUp {
          width: 100%;
          background-color: #fff;
          text-align: center;
          margin-bottom: 4px;
          padding:25px 25px 18px 25px;
          p {
            text-align: center;
            font-size: 16px;
          }
          ul {
            display: flex;
            flex-wrap:wrap;
            justify-content:center;
            li {
              width: 140px;
              margin-bottom: 12px;
              font-size: 12px;
              line-height: 12px;
              a {
                font-size: 12px;
                color: #dd2b23;
              }
            }
          }
        }
        .click {
          width: 100%;
          height: 50px;
          background-color: #fff;
          text-align: center;
          line-height: 50px;
          p {
            display: inline-block;
            font-size: 14px;
            margin: 0 auto;
            &.icon {
              position: relative;
              padding: 17px 17px 0 0;
              margin-left: 5px;
              top: 1px;
              span {
                position: absolute;
                display: inline-block;
                width: 17px;
                height: 3px;
                background-color: #dd2b23;
                top: 50%;
                transform: translateY(-50%);
                &:last-of-type {
                  top: 48%;
                  transform: rotate(90deg);
                  transition: transform .2s ease;
                }
              }
            }
          }
        }
        .active {
          p.icon {
            span:last-of-type { transform: rotate(180deg); }
          }
        }
        .showBox {
          padding: 30px 0;
          table {
            width: 100%;
            caption {
              font-size: 14px;
              line-height: 1;
              text-align: left;
            }
            tr {
              font-size: 12px;
              line-height: 1;
            }
            th {
              font-weight: normal;
              margin-top: 29px;
              margin-bottom: 15px;
              position: relative;
              &:before {
                position: absolute;
                content: '';
                width: 100%;
                height: 1px;
                background-color: #000;
                left: 0;
                top: 50%;
              }
              span {
                position: relative;
                display: inline-block;
                padding-right: 15px;
                background-color: #f7f7f7;
                z-index: 2;
              }
            }
            td {
              ul {
                display: flex;
                flex-wrap: wrap;
                li {
                  display: flex;
                  width: calc( calc(100% - 1px) / 2);
                  margin: 0 1px 1px 0;
                  &:nth-of-type(2n) { margin-right: 0; }
                  a {
                    display: flex;
                    width: 100%;
                    padding: 11px 17px 11px 12px;
                    background-color: #fff;
                    background: url(../img/contents/arrow_02.png) no-repeat center right 10px;
                    background-size: 6px 10px;
                    text-decoration: none;
                    line-height: 1.5;
                    letter-spacing: 0;
                    align-items: center;
                  }
                }
              }
            }
          }
          .block01 {
            margin-bottom: 28px;
            caption { margin-bottom: 20px; }
          }
          .block02 {
            th, td { display: block; }
          }
        }
      }
      #BnrArea {
        position: fixed;
        bottom: 0;
        width: 100%;
        padding: 15px;
            z-index: 60;
        img {
          width: 100%;
          box-shadow: 10px 0px 30px 0px rgba(0,0,0,0.16);
        }
      }
    }
  }

  #PageWorks.pageIndex {
    #MainImg {
      margin-bottom: 75px;
      .image {
        &_img { background-image: url(../img/contents/work/mainimg.jpg); }
      }
    }
  }

  #PageWorks.pageIndex,
  #PageWorks.pageCategory {
    #Main {
      .innerBasic {
        h3 {
          text-align: center;
          font-size: 20px;
          font-weight: 700;
          line-height: 1;
          letter-spacing: .05em;
          margin-bottom: 45px;
          position: relative;
          &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 60px;
            background-color: #000;
            bottom: 32px;
            left: 49.9%;
            transform: translateX(-50%);
          }
        }
      }
      .entryList {
        .innerBasic {
          padding: 0 15px;
          // ul {
          //   display: flex;
          //   flex-wrap: wrap;
          //   li {
          //     width: 48%;
          //     margin-right:4%;
          //     margin-bottom: 30px;
          //     text-align: center;
          //     line-height: 1.4;
          //     a {
          //       text-decoration: none;
          //       font-size: 14px;
          //       figure {
          //         margin-bottom: 5px;
          //         img {
          //           width: 100%;
          //         }
          //       }
          //     }

          //     &:nth-child(even) {
          //       margin-right: 0;
          //     }
          //   }
          // }
          .itemBoxs {
            display: flex;
            //justify-content: space-between;
            flex-wrap: wrap;
            }
          .itemBox {
            display: block;
            width: 48%;
            float: left;
            text-decoration: none;
            margin-bottom: 28px;
            margin-right:4%;
            .itemBoxImg {
              width: 100%;
              img {
                width: 100%;
                height: auto;
              }
            }
            .itemBoxText {
              font-size: 14px;
              line-height: 1.5;
              letter-spacing: .05em;
              text-align: center;
              margin: 6px 0 0;
            }
            &:nth-child(even) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  #PageWorks.pageCategory,
  #PageWorks.pageEntry {
    #MainImg {
      #MainImgInner {
        width: 100%;
        height: 160px;
        padding: 0;
      }
      .innerBg {
        width: 100%;
        margin: 0;
      }
      .title {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
    display: inline-block;
    margin: auto 0;
    line-height: 1.4;
    letter-spacing: .3em;
    font-size: 14px;
    color: #888;
          span {
      display: block;
      margin-left: -32px;
      font-size: 44px;
      letter-spacing: .05em;
    &:after {
      content: '';
      display: inline-block;
      width: 29px;
      height: 56px;
      background-image: url(../img/contents/icon_01.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      top: -7px;
      right: -32px;
    }
          }
        }
      }
    }
  }

  #PageWorks.pageCategory {
    #MainImg { margin-bottom: 75px; }
    #Main {
      #SearchBox {
        margin-bottom: 117px;
      }
    }
  }

  #PageWorks.pageEntry {
    #MainImg { margin-bottom: 75px; }
    #Main {
      .contSubBox {
        padding: 0 30px;
      }
      #ContBox01 {
        max-width: 1280px;
        margin: 0 auto;
        #ContSubBox01 {
          position: relative;
          margin-bottom: 40px;
          .redDotBg {
            position: absolute;
            width: 285px;
            height: 285px;
            background: url(../img/contents/reddot_sp.png) top left;
            background-size: 27px auto;
            z-index: -1;
          }
          dl {
            width: 100%;
            padding: 30px 0 0 0px;
//            border-bottom: 1px solid #e5e5e5;
            dt {
              text-align: right;
              margin-bottom: 5px;
              img { width: calc(100% - 30px); }
              .qrCode { display: none; }
            }
            dd {
              text-align: center;
              a {
                display: inline-block;
                text-decoration: none;
                font-family: 'Lato', sans-serif;
                font-size: 10px;
                color: #888;
                letter-spacing: .1em;
                line-height: 1;
                &:after {
                  display: inline-block;
                  content: '';
                  width: 13px;
                  height: 11px;
                  background: url(../img/contents/icon_link.png) no-repeat center;
                  background-size: 13px auto;
                  margin-left: 6px;
                  transform: translate(-2px, 2px);
                }
              }
            }
          }
          #MessageBox {
            position: relative;
            margin-top: 42px;
            &::before {
              content: "";
              position: absolute;
              top: -30px;
              right: 0;
              left: 0;
              margin: auto;
              width: 1px;
              height: 30px;
              background-color: #dd2b23;
            }
            padding-top: 10px;
            p {
              margin-bottom: 17px;
              text-align: center;
              em {
                font-weight: bold;
                font-size: 14px;
                color: #dd2b23;
              }
            }
            ul {
              position: relative;
              border: 1px solid #dd2a23;
              background-color: #f7f7f7;
              padding: 9px;
              width: 100%;
              max-width: 406px;
              margin: 0 auto;
              li {
                background-color: #fff;
                a {
                  display: block;
                  height: 48px;
                  padding-left: 15px;
                  background-image: url(../img/contents/arrow_02.png);
                  background-position: right 15px center;
                  background-repeat: no-repeat;
                  background-size: 8px auto;
                  font-size: 12px;
                  text-decoration: none;
                  line-height: 48px;
                  color: #000;
                }
                & + li {
                  margin-top: 2px;
                }
              }
              &::before {
                content: "";
                width: 18px;
                height:17px;
                position: absolute;
                top: -16px;
                left:19px;
                background-image:url(../img/contents/work/bubble_01.png);
                background-size: 18px auto;
              }
            }
          }
        }
        #ContSubBox02 {
          h3 {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.55;
            margin-bottom: 7px;
            padding-top: 50px;
            border-top: 1px solid #e5e5e5;
          }
          .labelList {
            margin-bottom: 38px;
            .label {
              display: inline-block;
              background-color: #dd2b23;
              color: #fff;
              font-size: 12px;
              line-height: 1;
              letter-spacing: 0;
              padding: 3px 7px 5px;
            }
          }
          .entryBody {
            p {
              line-height: 1.85;
              margin-bottom: 28px;
            }
            a { color: #dd2b23; }
          }
        }
      }
    }
    #Footer { padding-top: 25px; }
  }

/*########## 会社案内・プライバシーポリシー・リンク集・お知らせ・お客様メッセージ #########*/
/*########## メインイメージ #########*/
  #PageBit.pageIndex,
  #PagePolicy.pageIndex,
  #PageLink.pageIndex,
  #PageContact,
  #PageNews,
  #PageMessage,
  #PageRecruit.pageIndex {
    #MainImg {
      #MainImgInner {
        width: 100%;
        height: 160px;
        padding: 0;
      }
      .innerBg {
        width: 100%;
        margin: 0;
      }
      .title {
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        h2 {
          display: inline-block;
          margin: auto 0;
        }

      }
    }
  }
  #PagePolicy.pageIndex {
    #MainImg {
      h2 {
        span {
          margin-left: 8px;
          &:after {
            right: 8px;
          }
        }
      }
    }
  }
  #PageMessage {
    #MainImg {
      h2 {
        span {
          display: block;
          letter-spacing: .05em;
          &:after {
            content: "";
            position: absolute;
            display: inline-block;
            top: -7px;
            right: -32px;
            bottom: -4px;
            width: 29px;
            height: 56x;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/contents/icon_01.png);
            background-size: contain;
          }
        }
      }
    }
  }
  #PageContact.pageConfirm,
  #PageContact.pageThanks {
    #MainImg {
      h2 {
        span {
          display: block;
          letter-spacing: .05em;
          &:after {
            content: "";
            position: absolute;
            display: inline-block;
            top: -7px;
            right: -20px;
            bottom: -4px;
            width: 18px;
            height: 40px;
            background-repeat: no-repeat;
            background-position: center center;
            background-image: url(../img/contents/icon_01.png);
            background-size: contain;
          }
        }
      }
    }
  }

/*########## 会社案内 #########*/
#PageBit.pageIndex {
  #Main {
    .contBox {
      h3 {
        position: relative;
        padding-top: 70px;
        margin-bottom: 40px;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        &:before {
          position: absolute;
          content:'';
          top: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #000;
        }
      }
    }
    #ContBox01 {
      h3 {
        margin-bottom: 90px;
      }
      #ContSubBox01 {
        position: relative;
        padding-bottom: 30px;
        background-color: #f7f7f7;
        .bgImgBox {
          position: relative;
          background-color: #dd2a23;
          img {
            width: calc(100% - 30px);
            z-index: 12;
          }
        }
        .contentsBox {
          position: relative;
          margin-top: -45px;
          margin-left: 30px;
          padding: 50px 30px;
          width: calc(100% - 30px);
          background-color: #fff;
          z-index: 13;
          h4 {
            margin-bottom: 30px;
            font-size: 19px;
            font-weight: bold;
            letter-spacing: 0.07em;
            color: #dd2a23;
          }
          p {
            &.sign {
              text-align: right;
            }
          }

        }
      &:before {
        content: "CEO MESSSAGE";
        position: absolute;
        top: -45px;
        width: 100%;
        line-height: 1em;
        text-align: right;
        letter-spacing: .05em;
        font-family: "Lato", sans-serif;
        font-size: 50px;
        font-weight: 900;
        font-style: italic;
        color: #f7f7f7;
      }
      }
      #ContSubBox02 {
        padding-bottom: 75px;
        background-color: #f7f7f7;
        h4 {
          position: relative;
          padding: 45px 0 85px;
          background-color: #fff;
          text-align: center;
          font-weight: bold;
          font-size: 18px;
          color: #dd2a23;
          &:before {
          position: absolute;
          content:'';
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #dd2a23;
          }
        }
        .subBox {
          padding: 120px 30px 0;
        .contentsBox {
          position: relative;
          width: 100% ;
          padding: 75px 30px 30px;
          background-color: #fff;
          p {
            margin-left:7px;
            margin-right: 7px;
            margin-bottom: 15px;
            text-align: center;
            font-size: 12px;
            letter-spacing: 0.08em;
            color: #888888;
            strong {
              font-size: 14px;
              line-height: 2;
              color: #000;
              text-transform:uppercase;
            }
          }
          ul {
            width: 255px;
            margin: 0 auto;
          }
          li {
            position: relative;
            margin-bottom: 30px;
            padding-left: 30px;
            font-size: 14px;
            font-weight: bold;
            line-height: 26px;
            &:before {
              content: "";
              position: absolute;
              top: 5px;
              left: 0;
              width: 14px;
              height: 45px;
              background-image: url(../img/contents/bit/icon_excl.png);
              background-size: 14px auto;
              background-position: center top;
              background-repeat: no-repeat;
            }
          }
          &:before {
            position: absolute;
            content: "";
            top: -60px;
            left: 0;
            right: 0;
            margin: auto;
            width: 120px;
            height: 120px;
            background-image: url(../img/contents/bit/img_bit.png);
            background-position: center center;
            background-repeat: no-repeat;
            background-size: 120px auto;
          }
        }
      }
      }
    }
    #ContBox02 {
      padding: 75px 30px;
      table {
        width: 100%;
        border-bottom:solid 1px #dddddd;
        tr {
          border-top:solid 1px #dddddd;
          th {
            padding: 25px 0;
            width: 20%;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.8;
            color: #888;
          }
          td {
            padding: 25px 0 25px 25px;
            font-size: 14px;
            line-height: 1.8;
            a {
              text-decoration: underline;
              color: #dd2a23;
            }
          }
        }
      }
    }
      #ContBox03 {
        position: relative;
        padding-bottom: 30px;
        background-color: #f7f7f7;
        iframe {
          margin-left: 30px;
          width: calc(100% - 30px);
          height: 370px;
        }
        #LinkBox {
          position: absolute;
          width: 200px;
          height: 80px;
          bottom: 0;
          right: 30px;
          background-color: #fff;
          a {
            display: block;
            padding: 24px 34px 0;
            width: 100%;
            height: 100%;
            text-decoration: none;
            font-family: "Lato", sans-serif;
            font-weight: 900;
            font-size: 12px;
            letter-spacing: 0.16em;
            color: #dd2a23;
            .linkText {
              position: relative;
              background-image: url(../img/contents/icon_link.png);
              background-repeat: no-repeat;
              background-size: 13px auto;
              background-position: right center;
              &:after {
                position: absolute;
                content: "";
                bottom: -15px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #dd2a23;
              }
              span {
                display: inline-block;
              }
            }
            &:hover {
              .linkText {
                span {
                  animation-name:mouseonAction;
                  animation-duration:.3s;
                  animation-timing-function:cubic-bezier(.785,.135,.15,.86);
                  animation-fill-mode:both;
                    &:nth-child(1) {
                      animation-delay:0s
                    }
                    &:nth-child(2) {
                      animation-delay:30ms
                    }
                    &:nth-child(3) {
                      animation-delay:60ms
                    }
                    &:nth-child(4) {
                      animation-delay:90ms
                    }
                    &:nth-child(5) {
                      animation-delay:.12s
                    }
                    &:nth-child(6) {
                      animation-delay:.15s
                    }
                    &:nth-child(7) {
                      animation-delay:.18s
                    }
                    &:nth-child(8) {
                      animation-delay:.21s
                    }
                    &:nth-child(9) {
                      animation-delay:.24s
                    }
                    &:nth-child(10) {
                      animation-delay:.27s
                    }
                }
              }
            }
          }
        }
      }
  }
}

/*########## お問い合わせ #########*/
form {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    padding: 12px 12px 13px;
    width: 100%;
    background-color: #f1f1f1;
    font-size: 14px;
    line-height: 1.75;
    -webkit-appearance: none !important;
    border-radius: 0;
  }
  textarea::placeholder {
    background-color: #f1f1f1;
    color: #aaa;
    opacity: 1;
  }
  input:focus,
  textarea:focus {
    outline: 1px #000 solid;
    background-color: #fff;
  }
  .submitBtn input:focus,
  .btn input:focus {
    outline: none;
    background: none;
  }
  input {
    &#urgent_10-1 {
      margin: 0 8px;
      width: 172px;
    }
    &.keyword {
      margin-bottom: 10px;
    }
  }
  textarea {
    height: 250px;
    &#SiteUrl_1,
    &#SiteUrl_2,
    &#SiteUrl_4 {
      margin-top: -8px;
    }
    &.comment {
      height: 350px;
    }
  }
  div.radio {
    height: 35px;
    span {
      margin-right: 8px;
      input {
        display: block;
        width: 35px !important;
        height: 35px !important;
        border-radius: 50% !important;
      }
      height: 35px;
      width: 35px;
      background-image: url(../img/contents/radio_off.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 35px 35px;
      &.checked {
        background-image: url(../img/contents/radio_on.png);
      }
    }
  }
  div.checker {
    span {
      margin-right: 8px;
      input {
        display: block;
        width: 35px !important;
        height: 35px !important;
      }
      width: 35px;
      height: 35px;
      background-image: url(../img/contents/check_off.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 35px 35px;
      &.checked {
      background-image: url(../img/contents/check_on.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 35px 35px;
      }
    }
  }
  .btnBox {
    padding: 40px 0 85px;
    .submitBtn {
      text-align: center;
      span {
        position: relative;
        display: inline-block;
        &:after {
          content: "";
          position: absolute;
            top:0;
            bottom: 0;
            margin: auto;
            right: 15px;
            width: 10px;
            height: 15px;
            background-image: url(../img/contents/arrow_01.png);
            background-size: 10px auto;
            background-position: center;
        }
      }
      input {
        position: relative;
        display: inline-block;
        width: 250px;
        height: 75px;
        background-color: #dd2b23;
        color: #fff;
        line-height: 75px;
        border-radius: 0;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.1em;
      }
    }
    .btn {
      text-align: center;
      a,
      input {
        color: #dd2b23;
        text-decoration: underline;
      }
    }
  }
  dl.formTable {
    dt {
      position: relative;
      margin-bottom: 16px;
      padding-left: 50px;
      font-size: 14px;
      line-height: 1.8;
      &:before {
        position: absolute;
        top: -2px;
        left:0;
        width: 40px;
        height: 22px;
        padding-top: 8px;
        content: "必須";
        background-color: #dd2b23;
        font-size: 12px;
        text-align: center;
        line-height: 1;
        color: #fff;
      }
      span {
        display: inline-block;
        margin-left: -50px;
        padding-top: 8px;
        font-size: 12px;
        color: #888;
      }
      small {
        display: inline-block;
        margin-left: -50px;
        padding-top: 5px;
        font-size: 12px;
        color: #dd2b23;
      }
      &.any {
        &:before {
          content: "任意";
          background-color: #000000;
        }
      }
    }
    dd {
      margin-bottom: 32px;
      font-size: 14px;
      p {
        margin-bottom: 8px;
      }
      span {
        &.type01 {
          display: inline-block;
          width: 49%;
        }
        &.item01 {
          label {
            margin-bottom: 5px;
          }
        }
      }
      ul {
        li {
          margin-bottom: 10px;
        }
        &.list02 {
          li {
            display: inline-block;
            width: 49%;
            &:nth-of-type(3),
            &:nth-of-type(4) {
              margin-bottom: 5px;
            }
            &:nth-of-type(5) {
              width: 100%;
            }
          }
        }
        &.list03 {
          li {
            display: inline-block;
            width: 49%;
          }
        }
        &.list04 {
          li {
            display: inline-block;
            width: 49%;
            &:nth-of-type(3) {
              width: 100%;
            }
          }
        }
      }
      &.keyword {
        margin-bottom: 10px;
      }
    }
  }
}
#PageContact.pageIndex,
#PageContact.pageConfirm {
  #Main {
    .contBox {
      padding-top: 95px;
      background-color: #f7f7f7;
      h3 {
        position: relative;
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        line-height: 1.7;
        &:before {
          position: absolute;
          content: "";
          top: -65px;
          left: 0;
          right: 0;
          margin: auto;
          width: 1px;
          height: 60px;
          background-color: #000000;
        }
      }
      .contSubBox {
        margin-top: 40px;
        padding: 45px 30px 20px;
        background-color: #fff;
        h4 {
          margin-bottom: 40px;
          padding-top: 40px;
          border-top: solid 1px #e5e5e5;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
    #ContBox01 {
      padding: 0 30px 70px 30px;
      background-color: #ffffff;
      h3 {
        margin-bottom: 35px;
        color: #dd2b23;
        &:before {
          content: none;
        }
      }
      p {
        font-size: 16px;
        line-height: 2;
        &.notes {
          font-size: 12px;
          line-height: 1.8;
          letter-spacing: 0.1em;
          color: #888;
        }
      }
      #TelBox {
        margin-top: 35px;
        h4 {
          margin-bottom: 35px;
          font-size: 16px;
          text-align: center;
          color: #dd2b23;
        }
        a {
          position: relative;
          display: block;
          width: 255px;
          border: solid 1px #dd2b23;
          margin: 0 auto;
          padding: 10px 0;
          text-align: center;
          line-height: 1.3;
          color: #dd2b23;
          b {
            position: relative;
            font-family: 'Lato', sans-serif;
            font-size: 24px;
            font-weight: 900;
            &:after {
              content: "";
              position: absolute;
              left: 0;
              bottom: -2px;
              width: 100%;
              height: 1px;
              background-color: #dd2b23;
            }
          }
          span {
            font-size: 10px;
            letter-spacing: 0.2em;
          }
          &:before {
            position: absolute;
            content: "";
            top: -30px;
            right: 0;
            left: 0;
            margin: auto;
            height: 30px;
            width: 1px;
            background-color: #dd2b23;
          }
        }
      }
    }
  }
}
#PageContact.pageConfirm {
  #Main {
    min-height: 500px;
    #ContBox01 {
      .errorList {
        margin-bottom: 35px;
      }
      p.btn {
        text-align: center;
        a {
          color: #dd2b23;
          text-decoration: underline;
        }
      }
    }
  }
}

#PageContact.pageThanks {
  #Main {
    min-height: 300px;
    .contBox {
      h3 {
        position: relative;
        margin-bottom: 35px;
        line-height: 1.7;
        text-align: center;
        font-size: 20px;
        font-weight: bold;
        color: #dd2b23;
      }
      p {
        margin-left: 30px;
        margin-right: 30px;
        text-align: center;
        line-height: 2;
        font-size: 16px;
        &.error_messe {
          line-height: 1.75;
          margin-bottom: 5px;
        }
      }
      .errorList {
        margin-top: 30px;
        margin-bottom: 45px;
      }
      .contSubBox {
        padding: 45px 30px;
        dl.formTable {
          margin: 0 auto;
          dt {
            &:before {
              content: "任意";
              background-color: #000000;
            }
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6),
            &:nth-of-type(8),
            &:nth-of-type(10),
            &:last-of-type {
              &:before {
                content: "必須";
                background-color: #dd2b23;
              }
            }
            &:last-of-type {
              padding-top: 30px;
              border-top: solid 1px #e5e5e5;
              &:before {
                top: 29px;
              }
            }
          }
          dd {
            font-size: 16px;
            min-height: 25px;
            &:nth-of-type(8) ,
            &:nth-of-type(10){
              padding-bottom: 30px;
              border-bottom: solid 1px #e5e5e5;
            }
          }
        }
      }
    }
    #ContBox01 {
    }
    .btnBox {
      background-color: #f7f7f7;
    }
      .btn {
        a {
          color: #dd2b23;
          text-decoration: underline;
        }
      }
  }
}

/*########## プライバシーポリシー #########*/
#PagePolicy.pageIndex,
#PageLink.pageIndex {
  #Main {
    .contBox {
      .contSubBox {
        padding: 0 30px;
        background-color: #ffffff;
      }

    }
    #ContBox01 {
      #ContSubBox01 {
        padding-bottom: 50px;
      }
    }
    #ContBox02 {
      padding: 75px 0 75px;
      background-color: #f7f7f7;
      #ContSubBox02 {
        padding-top: 38px;
        dl {
          dt{
            margin-bottom: 20px;
            font-size: 18px;
            font-weight: bold;
            a {
              position: relative;
              color: #dd2b23;
              text-decoration: none;
              &:after {
                position: absolute;
                content: "";
                bottom: -2px;
                left: 0;
                width: 100%;
                height: 1px;
                background-color: #dd2b23;
              }
            }
          }
          dd {
            padding-bottom: 15px;
            border-bottom: #e5e5e5 solid 1px;
            margin-bottom: 40px;
            ul {
              margin-bottom: 20px;
              li {
                font-size: 14px;
                line-height: 26px;
                letter-spacing: 0.08em;
                padding-left: 1em;
                text-indent: -1em;
                &:before {
                  content: "・ ";
                }
              }
            }
            a {
              text-decoration: underline;
            }
            &:last-of-type {
              margin-bottom: 0;
              border:none;
            }
          }
        }
      }
    }
  }
}
#PageLink.pageIndex {
  #Main {
    #ContBox02 {
      #ContSubBox02 {
        padding-top: 45px;
        dl {
          border-bottom: #e5e5e5 solid 1px;
          dd{
            font-size: 14px;
            line-height: 1.75;
            letter-spacing: 0.1em;
            padding-bottom: 40px;
            a {
              color: #dd2b23;
            }
          }
        }
      }
      #ContSubBox03 {
        padding: 0 15px;
        ul.linkList {
          padding: 45px 0 20px 0;
          text-align: center;
          li {
            display: inline-block;
            margin-bottom: 25px;
            width: 112px;
            text-align: center;
            font-size: 12px;
            line-height: 1;
            color: #888888;
            a {
              color: #dd2b23;
            }
          }
        }
      }
    }
  }
}

/*########## 採用案内 #########*/
#PageRecruit.pageIndex {
  #Main {
    .contBox {
      p {
        text-align: center;
      }
    }
    #ContBox01 {
      padding: 75px 30px;
      table {
        width: 100%;
        border-bottom:solid 1px #dddddd;
        tr {
          border-top:solid 1px #dddddd;
          th {
            padding: 25px 0;
            width: 20%;
            font-size: 14px;
            font-weight: normal;
            line-height: 1.8;
            color: #888;
          }
          td {
            padding: 25px 0 25px 25px;
            font-size: 14px;
            line-height: 1.8;
			  h4{
				  font-weight: bold;
				  font-size: 16px;
				  margin-bottom: 8px;
			  }
			p{
				font-size: 14px;
				line-height: 1.8;
				text-align: justify;
			}
			ul{
				margin-bottom: 8px;
			 li{
				 &::before{
					 content: "・";
					 padding-right: 5px;
				 }		  
			 }
			}
            a {
              text-decoration: underline;
              color: #dd2a23;
            }
          }
        }
      }
    }
  }
}

/*########## お知らせ #########*/
#PageNews.pageIndex {
  #Main {
    .contBox {
      ul {
        li {
          margin-bottom: 80px;
          dl {
            dt {
              display: flex;
              margin-top: 20px;
              margin-bottom: 20px;
              line-height: 1.4;
              font-size: 16px;
              font-weight: normal;
              color: #dd2b23;
              align-items: center;
              &:before {
                content: "";
                margin-right: 10px;
                height: 1px;
                background-color: #dd2b23;
                flex-grow: 1;
              }
              &:after {
                content: "";
                margin-left: 40px;
                position: relative;
                flex-grow: 1;
              }
            }
            dd {
              margin: 0 15px;
            }
          }
        }
      }
    }
  }
}

/*########## お客様メッセージ #########*/
#PageMessage {
  #MainImg {
    margin-bottom: 70px;
  }
}
#PageMessage {
  #Main {
//    #ContBox01 {
      #ContSubBox01 {
        margin: 0 15px;
        padding: 0 30px 25px;
        border-bottom: solid 1px #e5e5e5;
        h3 {
          margin-bottom: 35px;
          text-align: center;
          font-size: 20px;
          font-weight: bold;
        }
        p {
          span {
            font-size: 12px;
            color: #888;
          }
        }
      }
      #ContSubBox02 {
        position: sticky;
        z-index: 60;
        top: 0;
        width: 100%;
        padding: 0 15px 0px 15px;
        background-color: #fff;
        ul {
          margin: 20px 35px 60px 10px;
          padding-bottom: 5px;
          li {
            display: inline-block;
            margin-bottom: 5px;
            margin-right: 10px;
            a {
              font-size: 12px;
              color: #dd2b23;
            }
          }
        }
      }
//    }
    #VoiceBox {
      padding-bottom: 30px;
      h3 {
        margin-top: 60px;
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
    .btnArea {
      margin-bottom: 100px;
    }
  }
  &.pageEntry {
    #MainImg {
      margin-bottom: 0;
    }
    #VoiceBox {
      h3 {
        padding-top: 30px;
        &:before {
         content: none;
        }
      }
    }
  }
}

/* end SP */


/*SPの上書き
#################### TABLET ####################*/

@media screen and (min-width: 668px) {
a[href^="tel:"] {
    pointer-events: none;
}
/*########## TOPPAGE #########*/
  #Page.pageIndex {
    #MainImg {
      .innerBasic {
        height: 730px;
        padding: 60px 0 30px;
      .innerBg {
        width: calc(100% - 120px);
        margin-left: 120px;
        background-color: #dd2b23;
        background-image: none;
        position: relative;
        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 30px;
          left: -60px;
          background-image: url(../img/contents/reddot_pc.png);
          background-position: top left;
          background-size: 54px 54px;
          z-index: -1;
        }
      }
      h2 {
        position: absolute;
        font-size: 88px;
        line-height: 1.14;
        letter-spacing: .05em;
        top: 150px;
        left: 60px;
        font-style: italic;
        z-index: 50;
        &:before {
          content: "";
          display: block;
          height: 40px;
          line-height: 1;
          letter-spacing: .05em;
          font-size: 44px;
          color: #000;
        }
        &:after {
          position: relative;
          right: -10px;
          bottom: 0;
          width: 43px;
          height: 85px;
          content: "";
          display: inline-block;
          background-repeat: no-repeat;
          background-position: center center;
          background-image: url(../img/contents/top/icon_01.png);
          background-size: contain;
        }
      }
      p.sub {
        top:405px;
        left:62px;
        font-size: 24px;
      }
      .image {
        &_wrap { height: 640px; }
        &_imglist {
          width: 100%;
          height: 100%;
        }
        &_img01:before { background-image: url(../img/contents/top/mainimg_01.jpg); }
        &_img02:before { background-image: url(../img/contents/top/mainimg_02.jpg); }
      }
      .swiper-pagination-bullet { display: inline-block; }
      .scroll_wrap {
        display: block;
        width: 90px;
        right: 0;
        /*right: 34px;*/
        bottom: 270px;
        height: 131px;
      .scroll_text {
        font-size: 12px;
        line-height: 90px;
         }
      }
      .text_wrap {
        display: block;
        width: 480px;
        height: 270px;
        padding: 54px 60px 24px;
        h3 {
          font-size: 20px;
          line-height: 1.66;
          margin-bottom: 19px;
        }
        p {
          font-size: 16px;
          line-height: 2;
          letter-spacing: 0.05em;
        }
      }
    }
  }
    #MainImg.type02 {
      p.sub {
        top:170px;
        font-size: 24px;
        &:after {
          bottom: -2px;
          width: 14px;
          height: 28px;
          background-size: 14px auto;
          transform: translateX(8px);
        }
      }
      h2 {
        top:190px;
        font-style: normal;
        font-size: 56px;
        line-height: 80px;
        letter-spacing: 0.08em;
        &:after {
          content: none;
        }
      }
      .scroll_wrap {
        bottom: 250px;
      }
      .text_wrap {
        height: 250px;
        padding: 54px 60px 4px;
        h3 {
          font-size: 20px;
        }
        p {
          letter-spacing: 0.05em;
        }
      }
    }
    #Main {
      .contSubBox {
        padding: 0 30px;
      }
      #Information {
        margin-top: 0;
        margin-bottom: -60px;
        padding: 120px 0;
        min-height: 340px;
        .entryBody {
          padding: 0 60px;
        }
        h3 {
          margin-bottom: 20px;
          font-size: 20px;
        }
        p {
          font-size: 16px;
        }
      }
      #ContBox01 {
        margin-top: 90px;
        padding: 117px 0 150px;
        h3 {
          font-size: 44px;
          transform: translate(-15px, -4px);
          &:after {
            width: 30px;
            height: 56px;
            transform: translate(2px, -16px);
          }
        }
        small {
          font-size: 14px;
          margin-top: 5px;
        }
        &:after { width: calc(100% - 90px); }
        .contSubBox {
          .itemBoxs { margin: 61px 0 29px; }
          .itemBox {
            width: 22.7%;
            margin-bottom: 28px;
            &:nth-of-type(n+13) { display: block; }
            &:nth-of-type(n+17) { display: none; }
            .itemBoxText {
              font-size: 14px;
              line-height: 1.4em;
              margin-top: 9px;
            }
          }
          .moreBtn {
            width: 260px;
            height: 60px;
            line-height: 60px;
            font-size: 12px;
            background-position: center right 29px;
          }
        }
      }
      #ContBox02 {
        margin: 59px 0 58px;
        .contSubBox {
          display: flex;
          justify-content: space-between;
          .boxItem {
            width: 33.33%;
            border-right: 1px solid #fff;
            &:nth-of-type(2) { transform: translateY(58px); }
            &:last-of-type { border-right: none; }
            .boxIn {
              height: 573px;
              dd {
                padding: 43px 40px;
                h3 {
                  font-size: 32px;
                  right: 11px;
                  &:after {
                    width: 19px;
                    height: 40px;
                  }
                }
                small {
                  font-size: 12px;
                  margin-top: 7px;
                }
                p.text {
                  line-height: 2;
                  margin-top: 20px;
                  margin-bottom: 21px;
                  font-size: 16px;
                }
                p.btn {
                  width: 110px;
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
      #ContBox03 {
        height: 545px;
        padding-top: 142px;
        background-size: 432px auto;
        background-position: bottom -5px right;
        .contSubBox {
          padding: 0 60px;
          flex-direction: row;
        }
        .boxTitle {
          width: 240px;
          text-align: left;
          .boxTitleText {
            float: none;
            h3 {
              font-size: 32px;
              &:after {
                width: 21px;
                height: 49px;
                transform: translate(5px, -12px);
              }
            }
            span { font-size: 12px; }
          }
          .boxTitleBtn {
            float: none;
            margin-top: 27px;
            .moreBtn {
              width: 120px;
              height: 40px;
              line-height: 35px;
              padding: 0 16px;
              background-position: center right 14px;
              span { font-size: 12px; }
            }
          }
        }
        .boxContent {
          width: calc(100% - 240px);
          margin-top: 0;
          .listWrap {
            margin-top: -18px;
            .list {
              a {
                padding: 20px 50px 22px 0;
                background-position: center right 28px;
              }
              time {
                font-size: 14px;
              }
              p { margin-top: 8px; }
              &:first-of-type a { border-top: none; }
              &:nth-of-type(2) {
                a { padding-bottom: 22px; }
              }
              &:last-of-type a { border-bottom: none; }
            }
          }
        }
      }
      #ContBox04 {
        background-size: auto 758px;
        background-position: 54% 20px;
        .ImageArea {
          margin-bottom: 88px;
          img.img_sp {
            margin-top: -60px;
          }
        }
        .textArea {
          h3 {
            font-size: 24px;
            margin-bottom: 50px;
          }
          p {
            line-height: 2;
            margin-bottom: 32px;
            font-size: 16px;
          }
        }
      }
    }
  }
/*########## 下層共通 #########*/
  #MainImg {
    margin-bottom: 0;
  }
    #ServiceLineUp {
      .innerBasic {
        padding: 0 30px;
      }
      #LineUpSupBox01 {
          padding-top: 100px;
          padding-bottom: 120px;
        h3 {
          font-size: 24px;
          line-height: 40px;
        }
        &:after {
            height: 90px;
        }
      }
      #LineUpSupBox02 {
        ul {
          li {
            a {
              display: flex;
              align-items: center;
              height: 100px;
              padding-left: 90px;
              font-size: 20px;
              &:before {
                width: 90px;
              }
              &:after {
                right: 30px;
              }
            }
          }
          &:nth-of-type(1) {
            li {
              &:nth-of-type(4) {
                a {
                  span {
                    margin-top: 0;
                    margin-left: 10px;
                  }
                }
              }
            }
          }
          &:nth-of-type(2) {
            li {
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 44px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 43px auto;
                  }
                }
              }
            }
          }
          &:nth-of-type(3) {
            li {
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
            }
          }
          &:nth-of-type(4) {
            li {
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 52px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
            }
          }
        }
      }
    }

  #PageService {
    #ServiceLineUp {
      #LineUpSupBox02 {
        padding-top: 80px;
        padding-bottom: 75px;
        h4 {
          margin-bottom: 50px;
          text-align: center;
          font-size: 28px;
          font-weight: bold;
        }
        ul {
          margin-bottom: 80px;
          width: 100%;
          li {
            margin-bottom: 2px;
            width: 100%;
            font-size: 20px;
            a {
              padding: 0 50px 0 90px;
              height: 100px;
              &:before  {
                width: 90px;
                height: 100px;
                background-repeat: no-repeat;
                background-position: center;
              }
              &:after {
                right: 30px;
              }
            }
          }
        }
      }
    }
  }

    #VoiceBox {
      padding-top: 60px;
      h3 {
        margin-bottom: 100px;
        padding-top: 120px;
        font-size: 28px;
        &:before {
          height: 90px;
        }
      }
      .serviceVoice {
        .innerBox {
          padding: 90px 60px 0;
          width: calc(100% - 60px);
          .wrap {
            &:after {
            content: '';
            clear: both;
            display: block;
            }
          dl {
            position: relative;
            float: left;
            width: 290px;
            border: none;
            dt {
              font-size: 16px;
            }
            dd {
              font-size: 12px;
              &.rqCode {
                position: absolute;
                top:204px;
                left: -16px;
                display: block;
                width: 100px;
                height: 100px;
                padding-top: 20px;
                padding-right: 0;
                border-radius: 50%;
                background-color: #fff;
                box-shadow:0px 0px 10px 0px rgba(0,0,0,0.1);
              }
            }
          }
          .contVoice {
            float: right;
            padding-top:0;
            width: calc(100% - 350px);
            h4 {
              margin-bottom: 23px;
              font-size: 24px;
              line-height: 40px;
            }
            p {
              font-size: 16px;
            }
          }
        }
        .userBox {
          padding-bottom: 0px;
          img {
            width: 100px;
          }
          p {
            right: 112px;
            font-size: 14px;
            line-height: 26px;
          }
        }
        .specBox {
          margin-top: 60px;
          padding-bottom: 55px;
          font-size: 14px;
        }
        }
        &:nth-of-type(2n) {
          .innerBox {
            margin-left: 30px;
          }
        }
      }
    }

/*########## Beginner #########*/
  #PageBeginner.pageIndex {
    #Main {
      #ContBox01 {
        .innerBasic {
          padding: 0 60px;
          h3 {
            font-size: 28px;
          }
          p {
            text-align: center;
          }
        }
      }
      #ContBox02 {
        padding-top: 180px;
        padding-bottom: 15px;
        &:before {
          top: -106px;
          font-size: 120px;
        }
        h3 {
          margin-bottom: 90px;
          font-size: 28px;
          &:before {
            height: 90px;
            top: -115px;
          }
        }
        .contSubBox {
          padding-top: 130px;
          margin-bottom: 135px;
          .contentsBox {
            margin: 0;
            margin-left: calc(100% - 580px);
            padding: 78px 60px 40px 60px;
            width: 100%;
            max-width: 580px;
            height: 383px;
            p {
              span {
                font-size: 16px;
              }
            }
            b {
              position: absolute;
              top: -55px;
              left: 440px;
              width: 120px;
              font-size: 16px;
              text-align: center;
              &:after {
                height: 30px;
              }
            }
            h4 {
              margin-top: 0;
              margin-bottom: 33px;
              font-size: 24px;
              line-height: 40px;
            }
          }
          .bgRedBox {
            margin-left: 60px;
            width: calc(50% + 120px);
            height: 468px;
            &:before {
              top: -60px;
              left: -60px;
            }
          }
        }
        #ContSubBox02 {
          .contentsBox {
            margin: 0;
            margin-right: calc(100% - 680px);
            b {
              left: 20px;
            }
          }
          .bgRedBox {
            margin-left: calc(50% - 180px);
            &:before {
              left: 60px;
            }
          }
        }
      }
    #ServiceLineUp {
      #LineUpSupBox02 {
        ul {
          &:nth-of-type(1) {
            li {
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 36px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 34px auto;
                  }
                }
              }
              &:nth-of-type(3) {
                a {
                  &:before {
                    background-size: 48px auto;
                  }
                }
              }
              &:nth-of-type(4) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
              &:nth-of-type(5) {
                a {
                  &:before {
                    background-size: 33px auto;
                  }
                }
              }
              &:nth-of-type(6) {
                a {
                  &:before {
                    background-size: 38px auto;
                  }
                }
              }
              &:nth-of-type(7) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
              &:nth-of-type(8) {
                a {
                  &:before {
                    background-size: 41px auto;
                  }
                }
              }
            }
          }
        }
      }
    }
    }
  }

/*########## Service #########*/
  #PageService {
    #MainImg {
      .title {
        span:before { width: 220px; }
      }
    }
    #ServiceLineUp {
      #LineUpSupBox02 {
        ul {
          &:nth-of-type(1) {
            li {
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 35px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 39px auto;
                  }
                }
              }
              &:nth-of-type(3) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
              &:nth-of-type(4) {
                a {
                  &:before {
                    background-size: 43px auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    #PriceBox {
      .innerBasic {
        padding: 120px 60px 60px;
        h3 {
          padding-top: 110px;
          font-size: 28px;
          &:before {
            height: 90px;
          }
        }
        p {
          text-align: center;
        }
        table {
          margin-top: 80px;
          border: none;
          tr {
            th {
              position: relative;
              display: inline-block;
              padding: 0;
              width: calc(100% - 170px);
              font-size: 16px;
              &:after {
                content: '';
                position: absolute;
                z-index: -5;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                width: 100%;
                height: 1px;
                background-color: #000;
              }
              span {
                display: inline-block;
                padding-right: 10px;
                background-color: #fff;
              }
            }
            td {
              &:nth-of-type(1) {
                display: inline-block;
                width: 170px;
                padding-left: 0;
                font-size: 16px;
                text-align: right;
                &:before {
                  content: none;
                }
              }
              &:nth-of-type(2) {
                border: none;
                margin-bottom: 10px;
                a {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }


    #PremiumBox {
      padding-top: 40px;
      h3 {
        padding-bottom: 120px;
        line-height: 40px;
        font-size: 24px;
        &:after {
          height: 90px;
        }
      }
      .contSubBox {
        padding-top: 178px;
        padding-bottom: 110px;
        &:before {
          border-width: 140px 140px 0px 0px;
        }
        &:after {
          top:40px;
          font-size: 20px;
        }
        .innerBasic {
          padding: 0 60px;
          h4 {
            margin-bottom: 40px;
            padding-top: 117px;
            font-size: 28px;
            background-size: 80px auto;
          }
          p {
            text-align: center;
            font-size: 16px;
            &.notes {
              font-size: 14px;
            }
            &.btn {
              margin-top: 50px;
              a {
                width: 260px;
                height: 60px;
                font-size: 12px;
                line-height: 60px;
                &:after {
                  right: 30px;
                }
              }
            }
          }
          dl {
            dt {
              padding-top: 0;
              width: 65px;
              height: 85px;
              text-align: left;
              font-size: 16px;
              line-height: 85px;
            }
            dd {
              margin-left: 50px;
              padding-left: 120px;
              width: calc(100% - 120px);
              height: 85px;
              line-height: 85px;
              font-size: 16px;
              background-size: auto 58px;
              &:before {
                width: 50px;
                left: -50px;
              }
              &:after {
                right: 30px;
              }
            }
            &:nth-of-type(1) {
              margin-top: 60px;
              dd {
                background-position: 33px center;
              }
            }
            &:nth-of-type(2) {
              dd {
                background-position: 33px center;
              }
            }
            &:nth-of-type(3) {
              dd {
                background-position: 30px center;
              }
            }
            &:nth-of-type(4) {
              dd {
                background-position: 33px center;
              }
            }
            &:nth-of-type(5) {
              dd {
                background-position: 33px center;
              }
            }
            &:nth-of-type(6) {
              margin-bottom: 30px;
              dd {
                background-position: 33px center;
              }
            }
          }

        }
      }
    }
  }

  #PageService.pageIndex {
    #ContBox01 {
      .innerBasic {
        padding: 0 30px;
        ul {
          li {
            margin-bottom: 20px;
            a {
              height: 260px;
              padding-top: 170px;
              &:before {
                }
              span {
                font-size:24px;
                line-height: 24px;
                &:after {
                width: 16px;
                height: 27px;
                margin-left: 12px;
                background-size: 15px auto;
                }
              }
            }
          &:nth-of-type(1) {
            a {
              &:before {
                top :66px;
                background-size: 74px auto;
              }
            }
          }
          &:nth-of-type(2) {
            a {
              &:before {
                top :60px;
                background-size: 70px auto;
              }
            }
          }
          &:nth-of-type(3) {
            a {
              &:before {
                top :62px;
                background-size: 107px auto;
              }
            }
          }
          &:nth-of-type(4) {
            a {
              &:before {
                top :55px;
                background-size: 80px auto;
              }
            }
          }
          }
        }
        .bnrBox {
          width: 100%;
          padding-top: 100px;
          padding-bottom: 28px;
          text-align: center;
          img {
            width: 100%;
            max-width: 980px;
          }
          a {
            img {
              transform: scale(1);
                transition: transform .6s ease;
            }
            &:hover {
              img { transform: scale(1.02); transition: transform .6s ease; }
            }
          }
        }
      }
    }
  }

  #PageService.pageNewhp,
  #PageService.pageRenewhp,
  #PageService.pageRecruit,
  #PageService.pagePremium,
  #PageService.pageResponsive,
  #PageService.pagePhotograph,
  #PageService.pageConsulting,
  #PageService.pageSeo,
  #PageService.pageCms,
  #PageService.pageListing,
  #PageService.pageManagement,
  #PageService.pageAnalytics,
  #PageService.pageLogo,
  #PageService.pagePrint {
    #MainImg {
      margin-bottom: 0px;
      #MainImgInner {
        height: 320px;
        .title {
          .icon {
            margin-bottom: 20px;
            transform: translateY(-10px);
          }
          h2 {
            font-size: 32px;
            line-height: 1;
            letter-spacing: .05em;
          }
        }
      }
    }
    #ContBox01 {
      h3 {
        margin-bottom: 75px;
        line-height: 44px;
        letter-spacing: .08em;
        font-size: 28px;
      }
      #ContSubBox01 {
        padding-top: 210px;
        padding-bottom: 35px;
        .contentsBox {
          padding-top: 100px;
          width: calc(50% + 280px);
          .innerBasic {
            padding: 0 60px;
            h4 {
              display: none;
            }
            dl {
              dt {
                position: relative;
                padding-left: 65px;
                font-size: 24px;
                line-height: 40px;
                span {
                  position: absolute;
                  top: 0px;
                  left: 0;
                  font-size: 32px;
                }
              }
              dd {
                margin-bottom: 68px;
                padding-bottom: 60px;
              }
            }
          }
          &:after {
            right: -105px;
            font-size: 120px;
          }
        }
        .bgRedBox {
          height: 320px;
          &:after {
            left: 60px;
            width: 120%;
            height: 320px;
            background-position: left -100px center;

          }
        }
        .bgWhiteBox {
          height: 90px;
        }
      }
    }
  }

  #PageService.pageNewhp {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 72px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageRenewhp {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 92px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageRecruit {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 56px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageResponsive {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 90px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pagePhotograph {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 80px;
            background-size: 72px auto;
          }
        }
      }
    }
    #VoiceBox {
      .serviceVoice {
        .innerBox {
          dl {
            dd.name {
              bottom: 93px;
            }
          }
        }
      }
    }
  }
  #PageService.pageConsulting {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 74px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageSeo {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 80px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageCms {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 72px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageListing {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 72px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageManagement {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 72px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageAnalytics {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 72px;
            height: 74px;
            background-size: 72px auto;
          }
        }
      }
    }
  }
  #PageService.pageLogo {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 102px;
            height: 55px;
            background-size: 102px auto;
          }
        }
      }
    }
  }
  #PageService.pagePrint {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 78px;
            height: 70px;
            background-size: 78px auto;
          }
        }
      }
    }
  }
  #PageService.pagePremium {
    #MainImg {
      #MainImgInner {
        &:before {
          border-width: 140px 140px 0px 0px;
        }
        &:after {
           top: 38px;
          font-size: 20px;
        }
        .title {
          .icon {
            width: 80px;
            height: 98px;
            background-size: 80px auto;
            margin-bottom: 0;
          }
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        padding-bottom: 0;
        .contentsBox {
          .innerBasic {
            dl {
              dt {
                span {
                  top: 0;
                }
              }
            }
          }
        }
      }
    }
    #PremiumBox {
      .contSubBox {
        padding-top: 60px;
        padding-bottom: 5px;
      }
    }
    #ContBox02 {
        padding-top: 180px;
        padding-bottom: 15px;
        .contSubBox {
          padding-top: 130px;
          margin-bottom: 135px;
          .contentsBox {
            margin: 0;
            margin-left: calc(100% - 580px);
            padding: 80px 60px 40px 60px;
            width: 100%;
            max-width: 580px;
            b {
              position: absolute;
              top: -55px;
              left: 440px;
              width: 120px;
              font-size: 16px;
              text-align: center;
              &:after {
                content: "";
                position: absolute;
                right: 0;
                bottom: -40px;
                left: 0;
                margin: auto;
                width: 1px;
                height: 30px;
                background-color: #b89447;
              }
            }
            h4 {
              margin-top: 0;
              margin-bottom: 30px;
              font-size: 24px;
              line-height: 40px;
            }
            p {
              font-size: 16px;
              line-height: 32px;
            }
            ul,ol {
              li {
                font-size: 16px;
                line-height: 32px;
              }
            }
          }
          .bgRedBox {
            top: 70px;
            width: calc(50% + 180px);
            height: 468px;
            &:before {
              top: -60px;
              left: 0px;
              width: calc(100% - 60px);
            }
          }
        }
        #ContSubBox01 {
          .contentsBox {
            h4 {
              padding-left: 70px;
              &:before {
              top: -28px;
              left: -7px;
              width: 60px;
              height: 60px;
              background-size: 60px;
              }
            }
          }
        }
        #ContSubBox02 {
          .contentsBox {
            h4 {
              padding-left: 70px;
              &:before {
              top: -20px;
              left: -2px;
              width: 60px;
              height: 60px;
              background-size: 60px;
              }
            }
          }
        }
        #ContSubBox03 {
          .contentsBox {
            h4 {
              padding-left: 70px;
              &:before {
              top: -14px;
              left: -4px;
              width: 56px;
              height: 56px;
              background-size: 56px;
              }
            }
          }
        }
        #ContSubBox04 {
          .contentsBox {
            h4 {
              padding-left: 70px;
              &:before {
              top: -20px;
              left: -3px;
              width: 60px;
              height: 60px;
              background-size: 60px;
              }
            }
          }
        }
        #ContSubBox05 {
          .contentsBox {
            h4 {
              padding-left: 70px;
              &:before {
              top: -16px;
              left: -4px;
              width: 60px;
              height: 60px;
              background-size: 60px;
              }
            }
          }
        }
        #ContSubBox06 {
          .contentsBox {
            h4 {
              padding-left: 70px;
              &:before {
              top: -22px;
              left: -5px;
              width: 60px;
              height: 60px;
              background-size: 60px;
              }
            }
          }
        }
        #ContSubBox02,
        #ContSubBox04,
        #ContSubBox06 {
          .contentsBox {
            margin: 0;
            margin-right: calc(100% - 680px);
            b {
              left: 20px;
            }
          }
          .bgRedBox {
            margin-left: calc(50% - 180px);
            &:before {
              left: 60px;
            }
          }
        }
      }
  }

/*#################### pageUniversity（LP） ####################*/
  #PageService.pageUniversity {
    .disp_tabsp {
      display: block;
    }
    #MainImg {
      #MainImgInner01 {
        height: 568px;
        padding-top: 45px;
        background-position: left -20px bottom -40px;
        h2 {
          margin: 0 auto;
          margin-top: 25px;
          width: 660px;
          span {
            display: inline-block;
            margin-bottom: 10px;
            font-size: 30px;
            letter-spacing: 0.1em;
          }
          b {
            padding: 19px 12px 23px 12px;
          }

        }
        #Label {
          width: 194px;
          height: 193px;
          top: 230px;
          left: calc( 50% + 188px );
          background-size: 194px auto;
        }
      }
      #MainImgInner02 {
        position: relative;
        height: 130px;
        .innerBox {
          position: absolute;
          top: -10px;
          left: 0;
          right: 0;
          margin: auto;
          display: flex;
          justify-content:center;
          align-items:center;
          h3 {
            position: relative;
            top: 0;
            margin: 0 12px 0 0;
            span {
              width:290px;
            }
          }
          div {
            padding-top: 0;
          }
        }
      }
      #MainImgInner04 {
        padding: 50px 0 5px 0;
        .innerBox {
          h3 {
            font-size: 18px;
          }
        }
      }
    }
    #Main {
      #ContBox01 {
        .contSubBox {
          margin: 0 auto;
          display: flex;
          flex-wrap: wrap;
          justify-content:space-between;
          width: 100%;
          max-width: 1000px;
          div {
            width: 48.7%;
            margin-bottom: 50px;
          }
        }
      }
      #ContBox02 {
        .contSubBox {
          .contentsBox {
            h4,p {
              max-width: 580px;
            }
            figure {
              max-width: 580px;
            }
          }
        }

      }
      #ContBox03 {
        padding-top: 66px;
        padding-bottom: 80px;
        background-image: url(../img/contents/service/university/wall01.png);
        background-position: center 146px;
        background-repeat: no-repeat;
        background-color: #F5F5F5;
        h3 {
          line-height: 1.7;
          margin-bottom: 16px;
        }
        .tableBox, table {
          max-width: 620px;
          margin: 0 auto;
        }
        /*2024.03.18 楊追加start*/
        .tableBox {
          table {
            th, td {
              font-size: 16px;
              &:first-of-type { width: 47.6%; }
            }
            td {
              padding: 21px 20px;
            }
          }
        }
        /*2024.03.18 楊追加end*/
      }
      #ContBox04 {
        background-size: 544px auto;
        background-color: #DD2B23;
        h3 {
          span {
            font-size: 24px;
          }
        }
        .contSubBox {
          dl {
            dd {
              text-align: center;
            }
          }
        }
      }
      #ContBox06 {
        .contentsBox {
          #ContSubBox01 {
            dl {
              position: relative;
              &:nth-of-type(2) {
                padding-bottom: 86px;
                dd {
                  width: calc( 100% - 375px );
                  div {
                    position: absolute;
                    top: 45px;
                    right: 0px;
                    width: 345px;
                    margin-top: 0;
                    &:before {
                      top: 12px;
                      left: -14px;
                      margin: 0;
                      width: 16px;
                      height: 19px;
                      background-image: url(../img/contents/service/university/wall07-2.png);
                      background-size: 16px auto;
                    }
                  }
                }
              }
            }
          }
          #ContSubBox02 {
            dl {
              position: relative;
              &:nth-of-type(1),
              &:nth-of-type(3) {
                padding-bottom: 46px;
                dd {
                  width: calc( 100% - 375px );
                  div {
                    position: absolute;
                    top: 45px;
                    right: 0px;
                    width: 345px;
                    margin-top: 0;
                    &:before {
                      top: 12px;
                      left: -14px;
                      margin: 0;
                      width: 16px;
                      height: 19px;
                      background-image: url(../img/contents/service/university/wall07-2.png);
                      background-size: 16px auto;
                    }
                  }
                }
              }
              &:nth-of-type(3) {
                padding-bottom: 66px;
              }
            }
          }
          #ContSubBox03 {
            dl {
              position: relative;
              &:nth-of-type(1),
              &:nth-of-type(2) {
                padding-bottom: 86px;
                dd {
                  width: calc( 100% - 375px );
                  div {
                    position: absolute;
                    top: 95px;
                    right: 0px;
                    width: 345px;
                    margin-top: 0;
                    &:before {
                      top: 12px;
                      left: -14px;
                      margin: 0;
                      width: 16px;
                      height: 19px;
                      background-image: url(../img/contents/service/university/wall07-2.png);
                      background-size: 16px auto;
                    }
                  }
                }
              }
              &:nth-of-type(2) {
                padding-bottom: 30px;
                dd {
                  div {
                    top: 45px;
                  }
                }
              }
            }
          }
        }
      }
      #ContBox07 {
        padding-top: 115px;
        &:before {
          height: 90px;
        }
      }
      #ContBox08 {
        margin: 0 auto;
        max-width: 905px;
        h3 + p {
          line-height: 1.6;
          margin-bottom: 30px;
        }
        .contSubBox {
          width: 100%;
          max-width: 875px;
          overflow: hidden;
          margin: 0 auto;
          margin-bottom: 20px;
          table {
            tbody {
              tr {
                th {
                  width: 235px;
                  font-size: 16px;
                }
                td {
                  font-size: 16px;
                  span {
                    font-size: 30px;
                  }
                }
              }
            }
 //           margin: 0 auto;
          }
        }
        table.aboutLp {
          tr {
            th {
              width: 253px;
              font-size: 16px;
            }
            td {
              text-align: center;
              font-size: 16px;
              span {
                font-size: 30px;
              }
            }
          }
        }
      }
    .contactBox {
        padding-top: 80px;
        padding-bottom: 103px;
      .innerBasic {
        padding: 0 60px;
        h3 {
          font-size: 24px;
          line-height: 24px;
          display: flex;
          justify-content: center;
          align-items: center;
    &:before, &:after {
      content: "";
      background-color: #000;
      height: 1px;
      width: 27px;
    }
    &:before {
      margin-right: 5px;
    transform: rotate(55deg);
    }
    &:after {
    transform: rotate(-55deg);
    }
        }
        .linkBox {
          margin-top: 17px;
          a {
            padding-top: 29px;
            height: 140px;
            font-size: 14px;
            letter-spacing: .4em;
            strong {
              font-size: 32px;
              &:after {
                width: 19px;
                height: 38px;

              }
            }
          }
          &:before {
            top:-60px;
            height: 60px;
          }
          &:after {
            top:0;
            bottom: 0;
            margin: auto;
            right: 30px;
            width: 10px;
            height: 15px;
            background-image: url(../img/contents/arrow_01.png);
            background-size: 10px auto;
            background-position: center;
            background-color: #dd2a23;
          }
        }
      }
    }
    }
  }



/*########## Process #########*/
  #PageProcess.pageIndex {
    #MainImg {
      margin-bottom: 0;
    }
    #Main {
      .contBox {
        padding-top: 60px;
        padding-bottom: 80px;
        min-height: 840px;
        .innerBasic {
          h3 {
            margin-bottom: 50px;
            padding-top: 120px;
            font-size: 28px;
            span {
              font-size: 16px;
            }
            &:before {
              height: 90px;
            }
          }
          .contSubBox {
            overflow: visible;
            .contentsBox {
              width: 580px;
              min-height: 180px;
              margin-top: -120px;
              margin-left: calc( 100% - 580px);
              padding: 50px 60px 0;
              p {
                font-size: 16px;
                &.notes {
                  font-size: 14px;
                  line-height: 26px;
                }
              }
            }
            .bgImgBox {
              position: relative;
              height: 360px;
              img {
                display: none;
              }
              &:after {
                position: absolute;
                bottom: -60px;
                left: 0;
                content: '';
                width: 100%;
                height: 60px;
                background-color: #dd2b23;
              }
            }
            .bgRedBox {
              display: none;
            }
          }
        }
      }
      #ContBox01 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_01.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox02 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_02.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox03 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_03.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox04 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_04.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox05 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_05.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox06{
        .bgImgBox {
          background-image: url(../img/contents/process/img_06.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox07 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_07.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox08 {
        .bgImgBox {
          background-image: url(../img/contents/process/img_08.jpg);
          background-position: center;
          background-size: cover;
        }
      }
      #ContBox09 {
        padding-top: 50px;
        height: 165px;
        min-height: auto;
        p {
          font-size: 14px;
          line-height: 2;
        }
      }
    }
  }

/*########## Works #########*/
  #PageWorks {
    #Main {
      #SearchBox {
        padding: 30px;
        margin-bottom: 79px;
        .pikcUp {
          padding-top: 35px;
          p {
            font-size: 20px;
            margin-bottom: 30px;
          }
          ul {
            li {
              border-left: solid 1px #e5e5e5;
              padding: 0 15px;
              width: auto;
              font-size: 14px;
              line-height: 20px;
              a {
                font-size: 14px;
              }
              &:nth-of-type(1) {
                border-left: none;
              }
            }
          }
        }
        .click {
          height: 80px;
          line-height: 80px;
          p {
            font-size: 16px;
            &.icon {
              padding: 22px 22px 0 0;
              margin-left: 8px;
              top: 2px;
              span {
                width: 22px;
                height: 4px;
                &:first-of-type {
                  top: 58%;
                }
              }
            }
          }
        }
        .showBox {
          padding-top: 59px;
          table {
            caption { font-size: 16px; }
            tr {
              font-size: 14px;
            }
            td {
              ul {
                li {
                  width: calc( calc(100% - 4px) / 3);
                  margin: 0 2px 2px 0;
                  &:nth-of-type(2n) { margin-right: 2px; }
                  &:nth-of-type(3n) { margin-right: 0; }
                  a { padding: 10px 17px 10px 12px; }
                }
              }
            }
          }
          .block01 {
            caption { margin-bottom: 15px; }
          }
        }
      }
      #BnrArea {
        padding-bottom: 18px;
        text-align: center;
        img {
          width: 100%;
          max-width: 1000px;
        }
      }
    }
  }

  #PageWorks.pageIndex {
    #MainImg {
      margin-bottom: 0;
    }
  }
  #PageWorks.pageIndex,
  #PageWorks.pageCategory {
    #Main {
      .innerBasic {
        h3 {
          font-size: 28px;
          margin-bottom: 89px;
          &:before {
            height: 90px;
            bottom: 59px;
            left: 50%;
          }
        }
      }
      .entryList {
        .innerBasic {
          padding: 0 30px;
          .itemBox {
            width: 31%;
            margin-right: 3.5%;
            .itemBoxText {
              font-size: 16px;
              line-height: 1.4em;
              margin-top: 10px;
            }
            &:nth-child(2n) {
              margin-right: 3.5%;
            }
            &:nth-child(3n) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  #PageWorks.pageCategory,
  #PageWorks.pageEntry {
    #MainImg {
      margin-bottom: 0;
      #MainImgInner { height: 320px; }
      .image_wrap { display: none; }
    }
  }

  #PageWorks.pageCategory {
    #MainImg { margin-bottom: 0; }
    #Main {
      #SearchBox {
        margin-bottom: 180px;
      }
    }
  }

  #PageWorks.pageEntry {
    #Main {
      .contSubBox { padding: 0 60px; }
      #ContBox01 {
        #ContSubBox01 {
          margin-bottom: 53px;
          .redDotBg {
            width: 556px;
            height: 525px;
            left: 0;
          }
          dl {
            padding: 60px 0 0 0px;
            dt {
              width: 494px;
              text-align: center;
              margin: 0 auto;
              margin-bottom: 23px;
              position: relative;
              img { width: 464px; }
              .qrCode {
                display: block;
                position: absolute;
                width: 100px;
                height: 100px;
                background-color: #fff;
                box-shadow: 0 0 10px rgba(0,0,0,.1);
                border-radius: 50%;
                bottom: -15px;
                left: 0;
                text-align: center;
                img {
                  width: 64px;
                  height: 64px;
                  position: relative;
                  top: 50%;
                  transform: translateY(-50%);
                }
              }
            }
            dd {
              a {
                font-size: 12px;
              }
            }
          }
          #MessageBox {
            padding-top: 30px;
            margin-top: 57px;
            &::before {
              height: 45px;
            }
            p {
              em {
                font-size: 16px;
              }
            }
            ul {
              li {
                a {
                  height: 58px;
                  line-height: 58px;
                  padding-left: 20px;
                  font-size: 14px;
                  background-position: right 20px center;
                }
              }
              &::before {
                content: "";
                width: 24px;
                height:22px;
                position: absolute;
                top: -21px;
                left:13px;
                background-image:url(../img/contents/work/bubble_01pc.png);
                background-size: 24px auto;
              }
            }
          }
        }
        #ContSubBox02 {
          h3 {
            font-size: 24px;
            margin-bottom: 19px;
          }
          .labelList { margin-bottom: 52px; }
          .entryBody {
            margin-bottom: 75px;
            p {
              letter-spacing: .05em;
              line-height: 2;
              margin-bottom: 32px;
            }
          }
        }
      }
    }
  }

/*########## 会社案内・プライバシーポリシー・リンク集・お問い合わせ・お客様の声 #########*/
/*########## メインイメージ #########*/
  #PageBit.pageIndex,
  #PagePolicy.pageIndex,
  #PageLink.pageIndex,
  #PageContact,
  #PageNews,
  #PageMessage,
  #PageRecruit.pageIndex {
    #MainImg {
      #MainImgInner { height: 320px; }
      .image_wrap { display: none; }
    }
  }
  #PagePolicy.pageIndex {
    #MainImg {
      h2 {
        span {
          margin-left: -11px;
          &:after {
            right: -22px;
          }
        }
      }
    }
  }

/*########## 会社案内 #########*/
#PageBit.pageIndex {
  #Main {
    .contBox {
      h3 {
        padding-top: 120px;
        font-size: 28px;
        &:before {
          height: 90px;
        }
      }
    }
    #ContBox01 {
      h3 {
        margin-bottom: 145px;
      }
      #ContSubBox01 {
        padding-bottom: 140px;
        &:before {
          top: -88px;
          font-size: 100px;
        }
        .bgImgBox {
          position: relative;
          height: 400px;
          img {
            display: none;
          }
          &:before {
            position: absolute;
            content: "";
            width: calc(100% - 60px);
            height: 400px;
            background-image: url(../img/contents/bit/img_01.jpg);
            background-size: cover;
            background-position: center top;
          }
        }
        .contentsBox {
          margin-top: -120px;
          margin-left: 60px;
          padding: 80px 60px 100px;
          width: calc(100% - 60px);
          h4 {
            font-size: 24px;
            line-height: 1.75;
          }
          p {
            max-width: 686px;
          }
        }
      }
      #ContSubBox02 {
        h4 {
          padding: 110px 0 115px;
          font-size: 24px;
          &:before {
            height: 90px;
          }
        }
        .subBox {
          padding: 120px 60px 0;
        .contentsBox {
          margin: 0 auto;
          height: 514px;
          max-width: 1040px;
          padding: 95px 0 0;
          p {
            margin-bottom: 60px;
            font-size: 14px;
            line-height: 2;
            strong {
              font-size: 16px;
            }
          }

          &:before {
            top: -80px;
            width: 160px;
            height: 160px;
            background-size: 160px auto;
          }
        }
      }
      }
    }
    #ContBox02 {
      padding: 120px 60px 180px 60px;
      #ContSubBox03 {
        margin-top: 50px;
        table {
          margin:0 auto;
          max-width: 920px;
          tr {
            th,td {
              font-size: 16px;
            }
            td {
              padding: 30px 0 30px 25px;
            }
          }
        }
      }
    }
    #ContBox03 {
      padding-bottom: 60px;
      iframe {
        margin-top: -60px;
        margin-left: 60px;
        width: calc(100% - 60px);
        height: 440px;
      }
      #LinkBox {
        right: 60px;
        height: 130px;
        a {
          padding-top: 52px;
        }
      }
    }
  }
}

/*########## お問い合わせ #########*/
  form {
  input[type="text"],
  input[type="email"],
  input[type="tel"],
  textarea {
    padding: 15px 15px 17px;
    font-size: 16px;
  }
  input#postno {
    width: 50%;
  }
  textarea {
    height: 150px;
    &#SiteUrl_1,
    &#SiteUrl_2,
    &#SiteUrl_4 {
      margin-top: 0;
    }
    &.comment {
      height: 240px;
    }
  }
  div.radio {
    margin-top: -5px;
    height: 40px;
    span {
      margin-right: 10px;
      input {
        width: 40px !important;
        height: 40px !important;
        border-radius: 50% !important;
      }
      height: 40px;
      width: 40px;
      background-size: 40px 40px;
    }
  }
  div.checker {
    span {
      margin-right: 10px;
      input {
        width: 40px !important;
        height: 40px !important;
      }
      width: 40px;
      height: 40px;
      background-size: 40px 40px;
      &.checked {
      background-size: 40px 40px;
      }
    }
  }
  .btnBox {
  .submitBtn {
    span {
      &:after {
        right: 30px;
        width: 12px;
        height: 19px;
        background-size: 12px auto;
      }
    }
    input {
      width: 400px;
      height: 100px;
      font-size: 20px;
      line-height: 100px;
      transition: all .3s;
      &:hover {
        background-color: #e92b23;
        transition: all .3s;
      }
    }
  }
  }

    dl.formTable {
      max-width: 860px;
      dt {
        font-size: 16px;
        &:before {
          top: 1px;
          font-size: 12px;
        }
        span,
        small {
          display: inline;
          margin-left: 0px;
          padding-top: 0;
          font-size: 14px;
        }
      }
      dd {
        margin-bottom: 28px;
        font-size: 16px;
        ul {
          li {
            margin-bottom: 12px;
          }
          &.list01 {
            li {
              display: inline-block;
              width: 290px;
              &:nth-of-type(2) {
                width: 350px;
              }
              &:nth-of-type(5) {
                width: 200px;
              }
            }
          }
          &.list02 {
            li {
              width: 150px;
              &:nth-of-type(5) {
                width: 100%;
              }
            }
          }
          &.list03 {
            li {
              width: 200px;
            }
          }
          &.list04 {
            li {
              width: 200px;
              &:nth-of-type(3) {
                width: 300px;
              }
            }
          }
        }
      }
    }
  }
#PageContact.pageIndex {
  #Main {
    #ContBox01 {
      p.notes {
        font-size: 14px;
      }
    }
    #ContBox03 {
      dl.formTable {
        &:nth-of-type(1) {
          dt {
            margin-bottom: 25px;
          }
          dd {
            margin-bottom: 70px;
          }
        }
        &:nth-of-type(2) {
          dt {
            &:nth-of-type(1),
            &:nth-of-type(2) {
              margin-bottom: 25px;
            }
          }
          dd {
            &:nth-of-type(5) {
              margin-bottom: 15px;
              padding-top: 5px;
            }
            &:nth-of-type(6) {
              margin-bottom: 70px;
            }
          }
        }
        &:nth-of-type(3) {
          dd {
            &:nth-of-type(2) {
              margin-bottom: 90px;
            }
          }
        }
      }
    }
  }
}
#PageContact.pageIndex,
#PageContact.pageConfirm {
  #Main {
    .contBox {
      h3 {
        font-size: 28px;
        letter-spacing: 0.1em;
        &:before {
          top: -110px;
          height: 90px;
        }
      }
      .contSubBox {
        margin: 0 auto;
        margin-top: 50px;
        padding: 90px 60px 70px 60px;
        max-width: 1040px;
        h4 {
          margin-bottom: 50px;
          padding-top: 80px;
          font-size: 24px;
        }
      }
    }
    #ContBox01 {
      p {
        text-align: center;
      }
      #TelBox {
        padding-top: 15px;
        h4 {
          margin-bottom: 45px;
          font-size: 20px;
        }
        a {
          padding: 20px 0;
          width: 400px;
          line-height: 1.2;
          b {
            font-size: 32px;
            &:after {
              content: none;
            }
          }
          span {
            font-size: 14px;
            letter-spacing: .1em;
          }
          &:before {
            top: -45px;
            height: 45px;
          }
        }
      }
    }
    #ContBox02 {
      padding: 170px 60px 0;
    }
    #ContBox03 {
      padding: 140px 60px 0;
    }
    #ContBox04 {
      padding: 140px 60px 0;
    }
  }
}
#PageContact.pageConfirm {
  #Main {
    #ContBox04 {
      .btnBox {
        position: relative;
        margin: 0 auto;
        max-width: 1040px;
        .btn {
          position: absolute;
          display: block;
          text-align: left;
          top: 80px;
          left: 0;
        }
      }
    }
  }
}
#PageContact.pageThanks {
  #Main {
    .contBox {
      h3 {
        letter-spacing: .1em;
        font-size: 28px;
      }
    }
  }
}

/*########## プライバシーポリシー #########*/
#PagePolicy.pageIndex,
#PageLink.pageIndex {
  #Main {
    .contBox {
      .contSubBox {
        p {
          font-size: 16px;
        }
      }
    }
    #ContBox01 {
      #ContSubBox01 {
        p {
          text-align: center;
        }
      }
    }
    #ContBox02 {
      padding: 90px 60px 140px 60px;
      #ContSubBox02 {
        margin: 0 auto;
        padding: 80px 60px 0;
        max-width: 1040px;
        dl {
          dt {
            margin-bottom: 30px;
            font-size: 24px;
          }
          dd {
            margin-bottom: 80px;
            padding-bottom: 55px;
            ul {
              li {
                font-size: 16px;
                line-height: 32px;
              }
            }
            &:last-of-type {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}

/*########## リンク #########*/
#PageLink.pageIndex {
  #Main {
    #ContBox02 {
      #ContSubBox02 {
        dl {
          dd {
            line-height: 1.8;
            font-size: 16px;
          }
        }
      }
      #ContSubBox03 {
        margin: 0 auto;
        max-width: 1040px;
        ul.linkList {
          padding-top: 90px;
          padding-bottom: 60px;
          li {
            width: 160px;
            font-size: 14px;
          }
        }
      }
    }
  }
}

/*########## お客様メッセージ #########*/
#PageMessage {
  #MainImg {
    margin-bottom: 0;
  }
}
#PageMessage {
  #Main {
//    #ContBox01 {
      #ContSubBox01 {
        h3 {
          font-size: 28px;
        }
        p {
          text-align: center;
          font-size: 16px;
          span {
            font-size: 14px;
          }
        }
      }
      #ContSubBox02 {
        width: 100%;
        margin: 0 auto;
        padding-top: 25px;
        ul {
          max-width: 960px;
          margin: 0px 100px 90px 100px;
          text-align: center;
          li {
            margin: 0 0 15px 0;
            padding: 0 15px 0 10px;
            display: inline-block;
            border-left: solid 1px #e5e5e5;
            line-height: 20px;
            a {
              display: inline-block;
              padding-bottom: 4px;
              line-height: 14px;
              font-size: 14px;
            }
          }
        }
      }
//    }
  }
  &.pageEntry {
    #TopicPath {
      margin-bottom: 0;
    }
  }
}

/* end TABLET */
}


/*SPとTABLETの上書き
#################### PC ####################*/
@media screen and (min-width: 1025px) {
/*########## TOPPAGE #########*/
  #Page.pageIndex {
    #MainImg,
    #MainImg.type02 {
      padding-top: 120px;
      .innerBasic {
        .image_wrap {
          width: calc(100% - 120px);
        }
        .scroll_wrap {
          width: 120px;
          right: 0px;
          bottom: 300px;
          .scroll_text {
            line-height: 120px;
          }
        }
        .text_wrap {
          height: 300px;
          padding: 54px 60px;
        }
      }
    }

    #Main {
      .contSubBox {
        max-width: 1280px;
        margin: 0 auto;
      }
      #Information {
        margin-bottom: -90px;
      }
      #ContBox01 {
        padding-bottom: 120px;
        margin-bottom: 119px;
        &:after {
          top: 0;
        }
        .contSubBox {
          padding: 0 60px;
          .itemBox {
            width: 15%;
            &:nth-of-type(n+17) { display: block; }
            .itemBoxImg {
              position: relative;
              overflow: hidden;
              img {
                transform: scale(1);
                transition: transform .6s ease;
              }
              &:before, &:after {
                position: absolute;
                content: '';
                opacity: 0;
                transition: opacity .6s ease;
              }
              &:before {
                z-index: 1;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,.7);
              }
              &:after {
                z-index: 2;
                content: 'DETAIL';
                width: auto;
                height: auto;
                color: #fff;
                font-size: 12px;
                line-height: 1;
                letter-spacing: .2em;
                padding-right: 22px;
                background: url(../img/contents/arrow_01.png) center right no-repeat;
                background-size: 7px 12px;
                top: 49%;
                left: 53%;
                transform: translate(-50%, -50%);
              }
            }
            &:hover {
              .itemBoxImg {
                img { transform: scale(1.12); }
                &:before, &:after { opacity: 1; }
              }
              .itemBoxText { color: #dd2b23; }
            }
          }
          .moreBtn:hover {
            background-color: #e92b23;
            transition: all .3s;
            span {
              animation-name: mouseonAction;
              animation-duration: .3s;
              animation-timing-function: cubic-bezier(.785, .135, .15, .86);
              animation-fill-mode: both;
              &:nth-of-type(1) { animation-delay: 0s; }
              &:nth-of-type(2) { animation-delay: 30ms; }
              &:nth-of-type(3) { animation-delay: 60ms; }
              &:nth-of-type(4) { animation-delay: 90ms; }
              &:nth-of-type(5) { animation-delay: .12s; }
              &:nth-of-type(6) { animation-delay: .15s; }
              &:nth-of-type(7) { animation-delay: .18s; }
              &:nth-of-type(8) { animation-delay: .21s; }
              &:nth-of-type(9) { animation-delay: .24s; }
            }
          }
        }
      }
      #ContBox02 {
        margin-bottom: 60px;
        .contSubBox {
          .boxItem {
            &:nth-of-type(2) { transform: translateY(60px); }
            &:hover {
              .boxIn {
                dt {
                  img { transform: scale(1.12); }
                }
                dd {
                  p.btn {
                    span {
                      animation-name: mouseonAction;
                      animation-duration: .3s;
                      animation-timing-function: cubic-bezier(.785, .135, .15, .86);
                      animation-fill-mode: both;
                      &:nth-of-type(1) { animation-delay: 0s; }
                      &:nth-of-type(2) { animation-delay: 30ms; }
                      &:nth-of-type(3) { animation-delay: 60ms; }
                      &:nth-of-type(4) { animation-delay: 90ms; }
                      &:nth-of-type(5) { animation-delay: .12s; }
                      &:nth-of-type(6) { animation-delay: .15s; }
                      &:nth-of-type(7) { animation-delay: .18s; }
                      &:nth-of-type(8) { animation-delay: .21s; }
                      &:nth-of-type(9) { animation-delay: .24s; }
                    }
                  }
                }
              }
            }
            .boxIn {
              height: 593px;
              dt {
                overflow: hidden;
                img {
                  transform: scale(1);
                  transition: transform 1s ease;
                }
              }
              dd {
                p.text { letter-spacing: 0; }
                p.btn{
                  letter-spacing: 0.04em;
                 span {
                  margin-left: -1px;
                  &:first-of-type { margin-left: 0; }
                }
              }
              }
            }
          }
        }
      }
      #ContBox03 {
        height: 460px;
        padding-top: 147px;
        .contSubBox {
          .listWrap {
            .list {
              a {
                padding: 20px 50px 25px 0;
                background-position: center right 30px;
              }
              time { margin-right: 31px; }
              p {
                display: inline-block;
                margin-top: 0;
              }
              &:nth-of-type(2) a { padding-bottom: 25px; }
              &:hover {
                a { background-position: center right 25px; }
                p { color: #dd2b23; }
              }
            }
          }
        }
      }
      #ContBox04 {
        position: relative;
        overflow: hidden;
        width: 100%;
        height: 626px;
        background-position: 78% 18px;
        .ImageArea {
          margin-bottom: 120px;
          img.img_sp {
            display: none;
          }
          img.img_pc {
            display: block;
            position: absolute;
            top: 110px;
            height: auto;
            &01 {
              width: 275px;
              right: calc(50% + 365px);
            }
            &02 {
              width: 292px;
              left: calc(50% + 348px);
            }
          }
        }
        .textArea {
          p {
            text-align: center;
          }
        }
      }
    }
  }
/*########## 下層共通 #########*/
    #ServiceLineUp {
      .innerBasic {
        padding: 0 60px;
      }
      #LineUpSupBox01 {
        padding-top: 100px;
        padding-bottom: 120px;
        &:after {
          height: 90px;
        }
      }
      #LineUpSupBox02 {
        padding-bottom: 75px;
          h4 {
            font-size: 28px;
          }
          ul {
            max-width: 1160px;
            font-size: 0;
            li {
              display: inline-block;
              margin-bottom: 2px;
              width:calc(50% - 2px);
              vertical-align: top;
              a {
                height: 120px;
                padding: 0 50px 0 90px;
                font-size: 20px;
                &:before {
                  width: 90px;
                  height: 120px;
                  background-repeat: no-repeat;
                  background-position: center;
                  transition: all .3s;
                }
                &:after {
                  transition: all .3s;
                }
                &:hover {
                  color: #dd2a23;
                  &:before {
                    transform:scale(1.1);
                  }
                  &:after {
                    transform:translateX(5px);
                  }
                }
              }
              &:nth-child(odd) {
                margin-right: 2px;
              }
            }
          }
        }
      }

    #VoiceBox {
      .serviceVoice {
        max-width: 1280px;
        margin: 0 auto;
        margin-bottom: 90px;
        padding-top: 60px;
        .innerBox {
          margin-left: 120px;
          width: calc(100% - 180px);
          .wrap {
            dl {
              width: 360px;
              dt {
                margin-bottom: 5px;
              }
              dd {
                &.rqCode {
                  top: 275px;
                }
                a {
                  &:hover {
                    color: #dd2b23;
                  }
                }
                .rqCode {
                  top: 275px;
                }
              }
            }
            .contVoice {
              width: calc(100% - 420px);
            }
          }
          .userBox  {
            p {
              line-height: 1.65;
            }
          }
        }
        .bgRedBox {
          left: 60px;
          width: calc(50% - 60px);
          height: calc(100% - 60px);
        }
        &:nth-of-type(2n) {
          .innerBox {
            margin-left: 60px;
          }
        }
      }
    }

/*########## Beginner #########*/
  #PageBeginner.pageIndex {
    #Main {
      #ContBox01 {
        h3 {
          margin-bottom: 35px;
        }
        padding-bottom: 210px;
      }
      #ContBox02 {
        h3 {
          margin-bottom: 120px;
        }
        .contSubBox {
          margin: 0 auto;
          margin-bottom: 135px;
          width: 100%;
          max-width: 1280px;

        }
      }
      #ServiceLineUp {
        #LineUpSupBox02 {
          padding-top: 85px;
          padding-bottom: 65px;
          h4 {
            margin-bottom: 50px;
          }
          ul {
            margin-bottom: 80px;
          }
        }
      }
    }
  }
/*########## Service #########*/
  #PageService {
    #ServiceLineUp {
      #LineUpSupBox02 {
        ul {
          &:nth-of-type(1) {
            li {
              width: calc(50% - 2px);
              a {
                height: 120px;
              }
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 32px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
              &:nth-of-type(3) {
                a {
                  &:before {
                    background-size: 42px auto;
                  }
                }
              }
              &:nth-of-type(4) {
                a {
                  &:before {
                    background-size: 44px auto;
                  }
                }
              }
            }
          }
          &:nth-of-type(2) {
            li {
              width: calc(50% - 2px);
              a {
                height: 120px;
              }
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 45px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 44px auto;
                  }
                }
              }
            }
          }
          &:nth-of-type(3) {
            li {
              width: calc(50% - 2px);
              a {
                height: 120px;
              }
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 42px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 42px auto;
                  }
                }
              }
            }
          }
          &:nth-of-type(4) {
            li {
              width: calc(50% - 2px);
              a {
                height: 120px;
              }
              &:nth-of-type(1) {
                a {
                  &:before {
                    background-size: 52px auto;
                  }
                }
              }
              &:nth-of-type(2) {
                a {
                  &:before {
                    background-size: 40px auto;
                  }
                }
              }
            }
          }
        }
      }
    }

    #PriceBox {
      .innerBasic {
        p {
          line-height: 1.75;
        }
        table {
          margin: 0 auto;
          margin-top: 80px;
          width: 920px;
          tr {
            th {
              width: 396px;
            }
            td {
              &:nth-of-type(1) {
                width: 226px;
                padding-left: 30px;
                text-align: left;
              }
              &:nth-of-type(2) {
                display: inline-block;
                margin: 0;
                padding: 5px 0 40px 0;
                width: 289px;
                border-left: #dddddd solid 1px;
              }
            }
            &:last-of-type {
              td {
                &:nth-of-type(2) {
                  padding: 5px 0;
                }
              }
            }
          }
        }
      }
    }

    #PremiumBox {
      .contSubBox {
        padding-top: 90px;
        .innerBasic {
          p {
            line-height: 1.65;
            &.notes {
              margin-top: 20px;
            }
            &.btn {
              a {
                transition: all .3s;
                span {
                  display: inline-block;
                }
                &:hover {
                  background-color: #cea44a;
                  span {
                    animation-name:mouseonAction;
                    animation-duration:.3s;
                    animation-timing-function:cubic-bezier(.785,.135,.15,.86);
                    animation-fill-mode:both;
                    &:nth-child(1) {
                      animation-delay:0s
                    }
                    &:nth-child(2) {
                      animation-delay:30ms
                    }
                    &:nth-child(3) {
                      animation-delay:60ms
                    }
                    &:nth-child(4) {
                      animation-delay:90ms
                    }
                    &:nth-child(5) {
                      animation-delay:.12s
                    }
                    &:nth-child(6) {
                      animation-delay:.15s
                    }
                    &:nth-child(7) {
                      animation-delay:.18s
                    }
                    &:nth-child(8) {
                      animation-delay:.21s
                    }
                  }
                }
              }
            }
          }
          dl {
            display: inline-block;
            width: 185px;
            margin-right: 5px;
            margin-top: 90px;
            dt {
              width: 185px;
              text-align: center;
              height: 52px;
              padding-bottom: 40px;
              line-height: 16px;
            }
            dd {
              width: 185px;
              height: 185px;
              margin: 0;
              padding: 130px 0 0 0;
              text-align: center;
              line-height: 1em;
              &:before {
                top:-30px;
                left:92px;
                width: 1px;
                height: 30px;
                margin: 0;
              }
              &:after {
                display: block;
                top: 185px;
                right: -54px;
                margin:0;
                content: '●\Aご承諾書類';
                text-align: center;
                width: 100px;
                height: 32px;
                white-space: pre;
              }
            }
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              dd {
                background-position: 50% 40%;
              }
            }
            &:nth-of-type(6) {
              margin-right: 0;
            }
          }
        }
      }
    }
  }

  #PageService.pageIndex {
    #ContBox01 {
      .innerBasic {
        padding: 0 60px;
        ul {
          width: 100%;
          max-width: 1160px;
          font-size: 0;
          li {
            display: inline-block;
            width:calc(50% - 10px);
            a {
              &:before {
                transition: all .3s;
                }
              span {
                font-size:24px;
                line-height: 1em;
                &:after {
                width: 16px;
                height: 26px;
                margin-left: 14px;
                background-size: 16px auto;
                transition: all .3s;
                }
              }
              &:hover {
                color: #dd2a23;
                &:before {
                  transform:scale(1.1)
                }
                span {
                  &:after {
                    transform:translateX(5px);
                  }
                }
              }
            }
            &:nth-child(odd) {
              margin-right: 19px;
            }
          }
        }
      }
    }
  }
  #PageService.pageNewhp,
  #PageService.pageRenewhp,
  #PageService.pageRecruit,
  #PageService.pagePremium,
  #PageService.pageResponsive,
  #PageService.pagePhotograph,
  #PageService.pageConsulting,
  #PageService.pageSeo,
  #PageService.pageCms,
  #PageService.pageListing,
  #PageService.pageManagement,
  #PageService.pageAnalytics,
  #PageService.pageLogo,
  #PageService.pagePrint {
    #ContBox01  {
      #ContSubBox01 {
        padding-bottom: 0;
        .contentsBox {
          .innerBasic {
            padding: 0 120px;
            max-width: 100%;
            h4 {
              display: block;
              top:150px;
              right: -120px;
              padding-right: 0px;
              font-size: 16px;
              writing-mode: vertical-rl;
              &:after {
                top:-40px;
                left: 0;
                right: 12px;
                margin: 0;
                width: 1px;
                height: 30px;
              }
            }
            dl {
              max-width: 680px;
              margin-right: 0px;
              dt {
                padding-left: 60px;
              }
              dd {
                margin-bottom: 80px;
                padding-bottom: 80px;
                line-height: 2;
              }
            }
          }
          &:after {
            top: 200px;
          }
        }
        .bgRedBox {
          &:after {
            left: 120px;
            width: calc(100% - 120px);
            background-position: center center;
          }
        }
      }
    }
  }
  #PageService.pageResponsive {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 14px;
            width: 66px;
            height: 82px;
            background-size: 66px auto;

          }
        }
      }
    }
  }
  #PageService.pageRenewhp {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 14px;
          }
        }
      }
    }
  }
  #PageService.pageRecruit {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            width: 98px;
            height: 74px;
            background-size: 98px auto;
          }
        }
      }
    }
  }
  #PageService.pageAnalytics {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 14px;
            width: 80px;
            height: 80px;
            background-size: 80px auto;
          }
        }
      }
    }
  }
  #PageService.pageCms {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 12px;
            width: 82px;
            height: 82px;
            background-size: 82px auto;
          }
          h2 {
            text-transform:none;
          }
        }
      }
    }
  }
  #PageService.pageSeo {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 4px;
            width: 88px;
            height: 97px;
            background-size: 88px auto;
          }
        }
      }
    }
  }
  #PageService.pageConsulting {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 13px;
            width: 84px;
            height: 84px;
            background-size: 84px auto;
          }
        }
      }
    }
  }
  #PageService.pageListing {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 12px;
            width: 78px;
            height: 78px;
            background-size: 78px auto;
          }
        }
      }
    }
  }
  #PageService.pageManagement {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 14px;
            width: 80px;
            height: 80px;
            background-size: 80px auto;
          }
        }
      }
    }
  }
  #PageService.pagePhotograph {
    #MainImg {
      #MainImgInner {
        .title {
          .icon {
            margin-bottom: 12px;
            width: 76px;
            height: 84px;
            background-size: 76px auto;
          }
        }
      }
    }
    #VoiceBox {
      .serviceVoice {
        .innerBox {
          dl {
            dd.name {
              bottom: 93px;
              font-size: 32px;
            }
          }
        }
      }
    }
  }
  #PageService.pagePremium {
    #PremiumBox {
      .contSubBox {
        padding-top: 30px;
      }
    }
      #ContBox02 {
        padding-top: 80px;
        .contSubBox {
          margin: 0 auto;
          margin-bottom: 110px;
          width: 100%;
          max-width: 1280px;
          .contentsBox {
            b {
              left: auto;
              right: 0;
              width: auto;
              font-size: 20px;
              &:after{
                bottom: -35px;
              }
            }
            p,li {
              font-size: 16px;
            }
          }

        }

        #ContSubBox01 {
          margin-bottom: 160px;
          .contentsBox {
            max-width: 640px;
            height: 710px;
            margin-left: calc(100% - 700px);

          }
          .bgRedBox {
            &:after {
              content: "";
              position: absolute;
              top: 468px;
              /*right: 160px;*/
              left: 300px;
              width: 360px;
              height: 360px;
              background-position: center;
              background-size: cover;
              background-image: url(../img/contents/service/premium_img02.jpg);
            }
          }
        }
        #ContSubBox02 {
          .contentsBox {
            max-width: 880px;
            height: 630px;
            margin-left:60px;
            b {
              left:0;
              right: auto;
            }
            .textBox {
              width: 280px;
              float: left;
            }
            figure {
              width: 420px;
              float: right;
              margin-top: -10px;
            }
          }
        }
        #ContSubBox03 {
          margin-bottom: 160px;
          .contentsBox {
            max-width: 830px;
            height: 716px;
            margin-left: calc(100% - 890px);
            .textBox {
              width: 350px;
              float: left;
            }
            figure {
              width: 290px;
              float: right;
              margin-top: -10px;
            }
          }
          .bgRedBox {
            &:after {
              content: "";
              position: absolute;
              top: 468px;
              /*right: 340px;*/
              left: 120px;
              width: 360px;
              height: 360px;
              background-position: center;
              background-size: cover;
              background-image: url(../img/contents/service/premium_img05.jpg);
            }
          }
        }
        #ContSubBox04 {
          .contentsBox {
            max-width: 940px;
            height: 650px;
            margin-left:60px;
            b {
              left:0;
              right: auto;
            }
            .textBox {
              width: 465px;
              float: left;
            }
            figure {
              width: 300px;
              float: right;
              margin-top: -10px;
            }
          }
        }
        #ContSubBox05 {
          .contentsBox {
            max-width: 940px;
            height: 550px;
            margin-left: calc(100% - 1000px);
            .textBox {
              width: 430px;
              float: left;
            }
            figure {
              width: 330px;
              float: right;
              margin-top: -10px;
            }
          }
        }
        #ContSubBox06 {
          .contentsBox {
            max-width: 670px;
            height: 348px;
            margin-left:60px;
            b {
              left:0;
              right: auto;
            }
            .textBox {
              width: 220px;
              float: left;
            }
            figure {
              width: 280px;
              float: right;
              margin-top: -10px;
            }
          }
        }

      }
  }
/*########## pageUniversity #########*/
  #PageService.pageUniversity {
    #Main {
      #ContBox01 {
        padding-bottom: 90px;
        .contSubBox {
          div {
            width: 487px;
            margin-bottom: 70px;
          }
        }
        .moreBtn {

        }
        .moreBtn:hover {
            background-color: #e92b23;
            transition: all .3s;
            span {
              animation-name: mouseonAction;
              animation-duration: .3s;
              animation-timing-function: cubic-bezier(.785, .135, .15, .86);
              animation-fill-mode: both;
              &:nth-of-type(1) { animation-delay: 0s; }
              &:nth-of-type(2) { animation-delay: 30ms; }
              &:nth-of-type(3) { animation-delay: 60ms; }
              &:nth-of-type(4) { animation-delay: 90ms; }
              &:nth-of-type(5) { animation-delay: .12s; }
              &:nth-of-type(6) { animation-delay: .15s; }
              &:nth-of-type(7) { animation-delay: .18s; }
              &:nth-of-type(8) { animation-delay: .21s; }
              &:nth-of-type(9) { animation-delay: .24s; }
            }
          }
      }
      #ContBox02 {
        padding-top: 113px;
        &:before {
          height: 90px;
        }
        .innerBasic {
          margin: 0 auto;
          max-width: 1440px;
        }
        h3 {
          margin-bottom: 142px;
          letter-spacing: 0.12em;
          span {
            display: inline-block;
            margin-bottom: 5px;
            font-size: 24px;
          }
        }
        .contSubBox {
          max-width: 1272px;
          padding-top: 0;
          z-index: 10;
          .contentsBox {
            display: flex;
            align-items:center;
            max-width: 1222px;
            height: 388px;
            padding-top: 0;
            h4 {
              margin-bottom: 15px;
            }
            .textArea {
              width: 425px;
              padding: 0 0 10px 0;
              p {
                margin: 0;
                letter-spacing: 0.04em;
              }
            }
            figure {
              margin: 0;
              max-width: 669px;
              width: 57.8%;
              height: 388px;
              overflow: hidden;
              img {
                display: none;
              }
            }
          }
          .bgRedBox {
            width: 50
            vw;
            max-width: 720px;
            height: 388px;
            z-index: -1;
          }
          &:nth-child(even) { //奇数
            .contentsBox {
              flex-direction:row-reverse;
              margin-left: 50px;
              margin-bottom: 115px;
              background-position: right 87px top -110px;
              background-size: 435px auto;
              b {
                top: -52px;
                right: 0;
                left: auto;
              }
              .textArea {
                margin-right: 60px;
              }
              figure {
                margin-right: 40px;
              }
            }
            .bgRedBox {
              top: -50px;
            }
          }
          &:nth-child(odd) {
            margin-right: 0;
            margin-left: auto;
            .contentsBox {
              margin-right: 50px;
              margin-bottom: 180px;
              background-position: left 58px top -110px;
              background-size: 435px auto;
              b {
                top: -52px;
                left: 0;
                right: auto;
              }
              .textArea {
                margin-left: 60px;
              }
              figure {
                margin-left: 40px;
              }
            }
            .bgRedBox {
              right: 0;
              top: 50px;
            }
          }
          &:last-of-type {
            .contentsBox {
              margin-bottom: 13px;
            }
          }
        }
        #ContSubBox01 {
          .contentsBox {
            align-items:flex-end;
            .textArea {
              align-self :center;
            }
            figure {
              //position: absolute;
              //bottom: 0px;
              //left: 10px;
              height: 478px;
              background-image: url(../img/contents/service/university/img08.png);
              background-size: cover;
              background-position: right -45px bottom;
              img {
                //display: block;
                //width: auto;
                //height: 100%;
              }
            }
          }
        }
        #ContSubBox02 {
          .contentsBox {
            figure {
              background-image: url(../img/contents/service/university/img09.jpg);
              background-size: cover;
              background-position: left top;
            }
          }
        }
        #ContSubBox03 {
          .contentsBox {
            figure {
              background-image: url(../img/contents/service/university/img10.jpg);
              background-size: cover;
              background-position: right top;
            }
          }
        }
        #ContSubBox04 {
          .contentsBox {
            figure {
              background-image: url(../img/contents/service/university/img11.png);
              background-size: auto 100%;
              background-position: center top;
              background-repeat: no-repeat;
            }
          }
        }
        #ContSubBox05 {
          .contentsBox {
            height: 450px;
            figure {
              height: 450px;
              background-image: url(../img/contents/service/university/img12.jpg);
              background-size: cover;
              background-position: right top;
            }
            .textArea {
              div.works {
                margin-top: 15px;
                display: flex;
                align-items: flex-end;
                img {
                  margin-right: 15px;
                  margin-bottom: 0;
                  width: 180px;
                }
                p {
                  text-align: left;
                  font-size: 12px;
                  a {
                    &:hover {
                      color: #dd2b23;
                    }
                  }
                }
              }
            }
          }
        }
        #ContSubBox06 {
          .contentsBox {
            figure {
              background-image: url(../img/contents/service/university/img13.jpg);
              background-size: auto 100%;
              background-position: center top;
              background-repeat: no-repeat;
            }
          }
        }
      }
      #ContBox03 {
        h3 {
          span {
            font-size: 24px;
          }
        }
      }
      #ContBox04 {
        padding-bottom: 65px;
        .contSubBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin: 0 auto;
          width: 100%;
          max-width: 1000px;
          dl {
            width: 49%;
            min-height: 240px;
            margin: 0 0 20px 0;
            &:nth-of-type(2),
            &:nth-of-type(1) {
              dt {
                padding-top: 93px;
              }
            }
          }
        }
      }
      #ContBox06 {
        padding-top: 65px;
        padding-bottom: 73px;
        h3 {
          span {
            display: inline-block;
            font-size: 24px;
            margin-bottom: 15px;
          }
        }
        .contentsBox {
          position: relative;
          padding: 50px 63px 15px 63px;
          p.notes {
            font-size: 12px;
          }
          h4 {
            position: absolute;
            top: -28px;
            width: 874px;
            height: 56px;
            font-size: 20px;
            & + p {
              margin: 0;
              text-align: right;
            }
          }
          .contSubBox {
            position: relative;
            padding: 0;
            margin-bottom: 20px;
            h5 {
              position: absolute;
              top: 0;
              left: 0;
              width: 56px;
              height: 100%;
              padding-right: 19px;
              -ms-writing-mode: tb-rl;
              writing-mode: vertical-rl;
              text-orientation: upright;
              letter-spacing: 0.15em;
              span {
                font-size: 18px;
                vertical-align: baseline;
              }
            }
            dl {
              padding-left: 74px;
              background-size: 56px auto;
              background-position: 180px top;
              &:last-of-type {
                background-size: 56px auto,56px auto;
              background-position: 180px bottom, 180px top;
              }
              dt {
                margin-bottom: 25px;
                padding-left: 200px;
                &:before {
                  width: 64px;
                  left: 128px;
                }
                b {
                  position: absolute;
                  top: 0;
                  left: 0;
                  &:after {
                    position: absolute;
                    top: 11px;
                    right: -8px;
                    content: "";
                    width: 9px;
                    height: 18px;
                    background-image: url(../img/contents/service/university/wall08.png);
                    background-size: 9px auto;
                  }
                }
              }
              dd {
                padding-left: 200px;
                padding-bottom: 30px;
                div {
                  strong {
                    font-size: 16px;
                  }
                  p {
                    line-height: 1.7;
                  }
                }
              }
            }
          }
          #ContSubBox01 {
            margin-top: 25px;
            h5 {
              padding-right: 19px;
            }
            dl:nth-of-type(2) {
              padding-bottom: 80px;
              dd {
                div {
                  top: 5px;
                }
              }
            }
          }
          #ContSubBox02 {
            dl:nth-of-type(1) {
              padding-bottom: 130px;
              dd {
                div {
                  top: 60px;
                }
              }
            }
            dl:nth-of-type(3) {
              padding-bottom: 32px;
              dd {
                div {
                  top: 0px;
                }
              }
            }
          }
          #ContSubBox03 {
            margin-bottom: 5px;
            dl:nth-of-type(1) {
              padding-bottom: 40px;
              dd {
                width: 365px;
                div {
                  top: 60px;
                  width: 402px;
                }
              }
            }
            dl:nth-of-type(2) {
              padding-bottom: 42px;
              background-size: 56px auto;
              background-position: 180px top;
              dd {
                div {
                  top: 58px;
                }
              }
            }
          }
        }
      }
      #ContBox07 {
        padding-bottom: 60px;
        h3 {
          margin-bottom: 36px;
          line-height: 1.6;
          small {
            font-size:24px;}
        }
        .contSubBox {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 800px;
          dl {
            margin: 0;
            width: 31%;
            padding: 132px 0 0 0;
            min-height: 284px;
            &:nth-of-type(1) {
              background-position: center 45px;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              padding: 132px 0 0 0;
              &:before {
              margin: 0;
              left: -35px;
              top: 50%;
              transform: translateY(-50%);
            }
            }
            &:nth-of-type(2) {
              background-position: center 38px;
            }
            &:nth-of-type(3) {
              background-position: center 33px;
            }
            dt {
              text-align: center;
            }
            dd {
              text-align: center;
            }
          }
        }
      }
      #ContBox08 {
        padding-top: 80px;
        padding-bottom: 30px;
        h3 {
          margin-bottom: 15px;
        }
        h3 + p{
          text-align: center;
        }
        table.disp_sptab {
          display: none;
        }
        .contSubBox {
          width: 875px;
          margin-bottom: 12px;
          table {
            width: 875px;
            max-width: 875px;
            margin-bottom: 5px;
            thead {
              tr {
                th {
                  padding: 11px 14px 12px 14px;
                  font-size: 18px;
                  div {
                    display: block;
                    margin-top: 7px;
                    padding: 10px;
                    background-color: #fff;
                    font-weight: normal;
                    font-size: 12px;
                    letter-spacing: 0;
                    color: #000;
                    span {
                      color: #DD2B23;
                    }
                  }
                }
              }
            }
            tbody {
              tr {
                th {
                  width: 185px;
                  font-size: 16px;
                }
                td {
                  width: 230px;
                  font-size: 16px;
                  span {
                    font-size: 30px;
                  }
                }
              }
            }
          }
        }
        table.aboutLp {
          margin-bottom: 5px;
        }
      }
      #ContBox09 {
        padding-bottom: 25px;
        div.bgWhiteBox {
          margin-bottom: 40px;
        }
        h3 {
          margin-bottom: 80px;
        }
        .contSubBox {
          position: relative;
          z-index: 10;
          max-width: 915px;
          padding-right: 45px;
          ol {
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            li {
              width: 135px;
              height: 135px;
              margin-bottom: 0;
              padding-top: 69px;
              border-radius: 50%;
              background-size: 54px auto;
              background-position: center 13px;
              line-height: 1.3;
              &:before {
                left: -37px;
                margin: 0;
                top: 65px;
              }
              &:after {
                top: -40px;
                right: -40px;
                z-index: -1;
              }
              &:nth-of-type(4),
              &:nth-of-type(5) {
                padding-top: 77px;
              }
            }
          }
          p {
            margin-right: -45px;
          }
        }
      }
      .contactBox {
      .innerBasic {
        h3 {
          margin-bottom: 25px;
        }
        .linkBox {
          &:after {
            right: 0;
            background-image: none;
            width: 60px;
            height: 1px;
            background-color: #fff;
          }
          a {
            padding-top: 29px;
            letter-spacing: 0.4em;
            font-size: 14px;
            strong {
              font-size: 32px;
              &:after {
                width: 19px;
                height: 38px;
              }
            }
            .linkText {
              display: block;
              height: 12px;
              position: absolute;
              top: 0;
              bottom: 0;
              margin: auto;
              right: 75px;
              font-family: 'Lato', sans-serif;
              font-weight: 900;
              font-size: 12px;
              line-height: 12px;
              letter-spacing: 0.2em;
              span {
                display: inline-block;
              }
            }
            &:hover {
              background-color: #e92b23;
              .linkText {
                span {
                  animation-name:mouseonAction;
                  animation-duration:.3s;
                  animation-timing-function:cubic-bezier(.785,.135,.15,.86);
                  animation-fill-mode:both;
                    &:nth-child(1) {
                      animation-delay:0s
                    }
                    &:nth-child(2) {
                      animation-delay:30ms
                    }
                    &:nth-child(3) {
                      animation-delay:60ms
                    }
                    &:nth-child(4) {
                      animation-delay:90ms
                    }
                    &:nth-child(5) {
                      animation-delay:.12s
                    }
                    &:nth-child(6) {
                      animation-delay:.15s
                    }
                    &:nth-child(7) {
                      animation-delay:.18s
                    }
                    &:nth-child(8) {
                      animation-delay:.21s
                    }
                }
              }
            }
          }
        }
      }
    }
    }
  }
/*########## Process #########*/
  #PageProcess.pageIndex {
    #Main {
      .contBox {
        .innerBasic {
          max-width: 1280px;
          .contSubBox {
            .contentsBox {
              margin-left: auto;
              margin-right: 60px;

            }
            .bgImgBox {
              margin: 0 auto;
              width: calc(100% - 240px);
              max-width: 1160px;
              &:after {
                content:none;
              }
            }
            .bgRedBox {
              display: block;
              top:90px;
              margin-left: 0;
              width: calc(50% + 160px);
              height: 330px;
            }
          }
        }
        &:nth-child(even) {
          .innerBasic {
            .contSubBox {
              .contentsBox {
                margin-left: 60px;
                margin-right: auto;
              }
              .bgRedBox {
                right: 0;
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  }

/*########## Works #########*/
  #PageWorks {
    #Main {
      #SearchBox {
        margin-bottom: 90px;
        padding: 30px 60px 45px;
        .innerBasic {
          padding: 0 60px;
        }
        .click:hover {
          p { color: #dd2b23; }
        }
        .showBox {
          table {
            td {
              ul {
                li {
                  a {
                    transition: all .3s;
                  }
                  &:hover {
                    a {
                      color: #dd2b23;
                      background-position: center right 5px;
                    }
                  }
                }
              }
            }
          }
          .block01 {
            margin-bottom: 55px;
            td {
              ul {
                li {
                  width: calc( calc(100% - 6px) / 4);
                  &:nth-of-type(3n) { margin-right: 2px; }
                  &:nth-of-type(4n) { margin-right: 0; }
                }
              }
            }
          }
          .block02 {
            caption { margin-bottom: 15px; }
            tr {
              display: flex;
              margin-bottom: 12px;
            }
            th {
              width: 195px;
              margin: 0;
              margin-right: 15px;
              &:before { top: 20px; }
              span { top: 13px; }
            }
            td {
              width: calc(100% - 210px);
            }
          }
        }
      }
    }
  }

  #PageWorks.pageIndex,
  #PageWorks.pageCategory {
    #Main {
      .entryList {
        .innerBasic {
          padding: 0 60px;
          max-width: 1280px;
          .itemBox {
            width: 22%;
            margin-right: 4%;
            margin-bottom: 57px;
              &:nth-child(2n) {
                margin-right: 4%;
              }
              &:nth-child(3n) {
                margin-right: 4%;
              }
              &:nth-child(4n) {
                margin-right: 0;
              }
            .itemBoxImg {
              position: relative;
              overflow: hidden;
              img {
                transform: scale(1);
                transition: transform .6s ease;
              }
              &:before, &:after {
                position: absolute;
                content: '';
                opacity: 0;
                transition: opacity .6s ease;
              }
              &:before {
                z-index: 1;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                background-color: rgba(0,0,0,.7);
              }
              &:after {
                z-index: 2;
                content: 'DETAIL';
                width: auto;
                height: auto;
                color: #fff;
                font-size: 12px;
                line-height: 1;
                letter-spacing: .2em;
                padding-right: 22px;
                background: url(../img/contents/arrow_01.png) center right no-repeat;
                background-size: 7px 12px;
                top: 49%;
                left: 53%;
                transform: translate(-50%, -50%);
              }
            }
            &:hover {
              .itemBoxImg {
                img { transform: scale(1.12); }
                &:before, &:after { opacity: 1; }
              }
              .itemBoxText {
                color: #dd2b23;
                transition: all .3s;
              }
            }
          }
        }
      }
    }
  }

  #PageWorks.pageEntry {
    #MainImg { margin-bottom: 0; }
    #Main {
      .contSubBox { padding: 0;}
      #ContBox01 {
        display: flex;
        flex-wrap: wrap;
        padding: 11px 60px 0;
        margin-bottom: 60px;
        #ContSubBox01 {
          width: 466px;
          margin-right: 60px;
          .redDotBg {
            width: 406px;
            height: 466px;
          }
          dl {
            width: 100%;
            border-bottom: none;
            dt {
              width: 100%;
              text-align: right;
              img { width: 406px; }
              .qrCode { transform: translateX(45px); }
            }
            dd {
              line-height: 21px;
              a {
                transform: translateX(30px);
                &:hover { color: #dd2b23; }
              }
            }
          }
          #MessageBox {
            border: none;
            width: 406px;
            margin-left: 60px;
          }
        }
        #ContSubBox02 {
          width: calc(100% - 526px);
          padding-top: 53px;
          padding-right: 60px;
          h3 {
              padding-top: 0;
              border-top: none;
            }
          .entryBody {
            margin-bottom: 0;
            a:hover { text-decoration: none; }
          }
        }
      }
    }
  }

/*########## 会社案内 #########*/
#PageBit.pageIndex {
  #Main {
    #ContBox01 {
      #ContSubBox01 {
        &:before {
          top: -105px;
          font-size: 120px;
        }
        &:after {
          position: absolute;
          content: "";
          bottom: 0;
          right: calc(50% + 80px);
          width: calc(50% - 140px);
          max-width: 500px;
          height: 500px;
          background-image: url(../img/contents/bit/img_02.jpg);
          background-size: 100%;
          background-repeat: no-repeat;
          background-position: right bottom;

        }
        .bgImgBox {
          height: 660px;
          &:before {
            width: calc(100% - 120px);
            height: 660px;
          }
        }
        .contentsBox {
          margin-top: -370px;
          margin-left: calc(50% - 280px);
          padding: 100px 120px 75px;
          width: calc(50% + 280px);
        }
      }
      #ContSubBox02 {

        .subBox {
        .contentsBox {
          height: 430px;
          ul {
            margin: 0 auto;
            width: 94%;
            max-width: 900px;
            text-align: center;
            display: flex;
            justify-content: space-between;
            li {
              margin-bottom: 0;
              padding-top: 70px;
              padding-left: 0;
              width: 280px;
              text-align: center;
              font-size: 16px;
              line-height: 32px;
              &:before {
                width: 100%;
                height: 60px;
                background-position: center top;
                background-size: 18px auto;
              }

            }
          }
        }
      }
      }
    }
    #ContBox02 {
      padding: 120px 30px 180px 30px;
      table {
        tr {
          td {
            a:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
    #ContBox03 {
      #LinkBox {
        left: calc(50% + 320px);
        right: auto;
      }
    }
  }
}
/*########## お問い合わせ #########*/
form {
  dl.formTable {
    dt {
      span,
      small {
        margin-left: 15px;
      }
    }
  }
  .btnBox {
    padding: 60px 0 85px;
    .btn {
      input:hover {
        text-decoration: none;
      }
    }
  }
}
#PageContact.pageIndex,
#PageContact.pageConfirm {
  #Main {
    .contBox {
      .contSubBox {
         margin-top: 50px;
         padding: 90px 90px 58px;
      }
    }
  }
}

/*########## プライバシーポリシー #########*/
#PagePolicy.pageIndex,
#PageLink.pageIndex {
  #Main {
    #ContBox02 {
      #ContSubBox02 {
        padding: 80px 90px 0;
      }
    }
  }
}

/*########## リンク集 #########*/
#PageLink.pageIndex {
  #Main {
    #ContBox02 {
      #ContSubBox02 {
        dl {
          dd {
            a:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }
}
/*########## お客様の声 #########*/
#PageMessage {
  #Main {
    #ContSubBox01 {
      width: 960px;
      margin: 0 auto
    }
    #ContSubBox02 {
      top: 79px;
      ul {
        width: 960px;
        margin: 0 auto;
        margin-bottom: 90px;
      }
    }
  }
}



/*end PC */
}



/*
@media screen and (min-width: 1220px) {
  #PageBeginner.pageIndex {
    #Main {
      #ContBox02 {
        #ContSubBox02 {
          .contentsBox {
            margin-left:calc((100% - 1220px) / 2 ) ;
          }
        }
      }
    }
  }
}*/

@media screen and (min-width: 1350px) {
  #PageService.pageUniversity {
    .disp_tabsp {
      display: none;
    }
    .disp_1360 {
      display: block;
    }
    #MainImg {
      overflow: hidden;
      #ImageBox {
        position: absolute;
        top: 120px;
        left: calc( 50% - 48px );
        height: 1010px;
        width: 919px;
        background-image: url(../img/contents/service/university/wall13.png);
        background-size: 919px auto;
      }
      #MainImgInner01 {
        height: 570px;
        padding-top: 57px;
        background-image: url(../img/contents/service/university/wall11_pc.png);
        background-position: center;
        background-size: 730px auto;
        #Slider {
          display: none;
        }
        h2 {
          width: 1160px;
          font-size: 63px;
          span {
            margin-bottom: 0;
            font-size: 40px;
            line-height: 1.4;
          }
          b {
            padding: 27px 16px 36px 20px;
            margin-right: 15px;
            margin-top: 20px;
            margin-bottom: 7px;
            font-size: 81px;
          }
        }
        #Label {
          top: 63px;
          left: calc(50% - 27px);
          width: 252px;
          height: 252px;
          background-image: url(../img/contents/service/university/wall10_pc.png);
          background-size: 252px auto;
          z-index: 50;
        }
      }
      #MainImgInner02 {
        height: 180px;
        background: linear-gradient(120deg, rgba(255,235,99,1) 20%, rgba(255,255,255,1) 100%);
        .innerBox {
          position: relative;
          display: block;
          top: 0;
          right: calc( 50% - 0px );
          height: 180px;
          width: 50%;
          margin: 0;
          background-image: url(../img/contents/service/university/wall12.png);
          background-repeat: no-repeat;
          background-position: right 250px top 24px;
          h3{
            position: absolute;
            top: 32px;
            left: auto;
            right: 283px;
            margin: 0;
            text-align: left;
            span {
              background: none;
              text-align: left;
            }
          }
          div {
            position: absolute;
            top: 20px;
            right: 12px;
            align-self:flex-end;
            p:first-of-type {
              padding-top: 80px;
              margin-right: 0px;
              width: 330px;
              font-size: 30px;
            }
            p:last-of-type {
              padding-top: 96px;
              padding-left: 127px;
              padding-bottom: 15px;
              background-image: url(../img/contents/service/university/wall09_pc.png);
              background-size: 174px auto;
            }
          }
        }
      }
      #MainImgInner03 {
        padding-top: 26px;
        height: 300px;
        .innerBox {
          position: relative;
          margin: 0 auto;
          h4 {
            position: absolute;
            top: 10px;
            left: 0;
            right: 0px;
            margin: auto;
            max-width: 440px;
            font-size: 20px;
          }
          ul {
            position: absolute;
            top: 90px;
            left: 0;
            right: 0;
            margin: auto;
            li {
              width: 117px;
              height: 100px;
              padding-top: 68px;
            }
          }
        }
      }
      #MainImgInner04 {
        height: 260px;
        position: relative;
        padding:0;
        .innerBox {
          position: absolute;
          top: 55px;
          right: calc(50% + 30px);
          margin: 0;
          padding: 22px 20px;
          &::before {
            left: -22px;
            margin: 0;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1281px) {
/*########## TOPPAGE #########*/
  #Page.pageIndex {
    #Main {
      #ContBox04 {
        .boxBg {
          .imageMap {
            max-width: 100%;
            background-position: top 1px left 46%;
          }
        }
      }
    }
  }
/*########## Beginner #########*/
  #PageBeginner.pageIndex {
    #Main {
      #ContBox02 {
        .contSubBox {
          margin: 0 auto;
          margin-bottom: 135px;
          width: 100%;
          max-width: 1280px;
          .contentsBox {
            margin-left: 640px;
          }
        }
        #ContSubBox02 {
          .contentsBox {
            margin: 0;
            margin-left: 60px;
          }
        }
      }
    }
  }

  #PageService.pageNewhp,
  #PageService.pageRenewhp,
  #PageService.pageRecruit,
  #PageService.pagePremium,
  #PageService.pageResponsive,
  #PageService.pagePhotograph,
  #PageService.pageConsulting,
  #PageService.pageSeo,
  #PageService.pageCms,
  #PageService.pageListing,
  #PageService.pageManagement,
  #PageService.pageAnalytics,
  #PageService.pageLogo,
  #PageService.pagePrint {
    #ContBox01  {
      #ContSubBox01 {
        .contentsBox {
          .innerBasic {
            h4 {
              right: -235px;
            }
          }
        }
      }
    }
  }

/*########## pageUniversity #########*/
  #PageService.pageUniversity {
    #Main {
      #ContBox02 {
        margin: 0 auto;
        max-width: 1440px;
      }
    }
  }

}

/*#################### PageLink ####################*/
#PageLink.pageIndex {
  #Main {
    #ContBox02 {
      #ContSubBox03 {
        ul.linkList {
          li {
            border-left: #e5e5e5 solid 1px;
            &:first-of-type,
            &:nth-of-type(3) {
              border: none;
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 375px) {
  #PageLink.pageIndex {
    #Main {
      #ContBox02 {
        #ContSubBox03 {
          ul.linkList {
            li {
              &:nth-of-type(3) {
                border-left: #e5e5e5 solid 1px;
              }
              &:nth-of-type(4) {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 491px) {
  #PageLink.pageIndex {
    #Main {
      #ContBox02 {
        #ContSubBox03 {
          ul.linkList {
            li {
              &:nth-of-type(4) {
                border-left: #e5e5e5 solid 1px;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 668px) {
  #PageLink.pageIndex {
    #Main {
      #ContBox02 {
        #ContSubBox03 {
          ul.linkList {
            li {
              &:nth-of-type(4) {
                border: none;
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (min-width: 803px) {
  #PageLink.pageIndex {
    #Main {
      #ContBox02 {
        #ContSubBox03 {
          ul.linkList {
            li {
              &:nth-of-type(4) {
                border-left: #e5e5e5 solid 1px;
              }
            }
          }
        }
      }
    }
  }
}


/*end*/


